import React,{useState,useEffect} from 'react'
import appData from './../AppData'
import { ComConst } from '../WTConst'





const SERVER = ComConst.SERVERPATH+'/api/'
//const SERVER = 'http://192.168.2.210:8081/api/'
//const SERVER = 'http://217.160.171.4:8081/api/'
//const SERVER = 'https://217.160.171.4:8081/api/'
//const SERVER = 'api/'


async function fetchWithTimeout(url, opts = {}, timeout = 5000) {
    //from https://davidwalsh.name/fetch-with-timeout
    // Create a signal with timeout
    const signal = AbortSignal.timeout(timeout);
  
    // Make the fetch request
    const _fetchPromise = fetch(url, {
      ...opts,
      signal,
    });
  
    // Await the fetch with a catch in case it's aborted which signals an error
    const result = await _fetchPromise;
    return result;
  };





class Engine {
    static connectInfo(){
        return 'Server: ' + SERVER + '  \nClient version :'+appData.getVersion();
    }
    static async testSimpleConnectToServer(){
        try{
            const r1 = await fetch(SERVER+'m000/test');
            const r2 = await r1.json()
            alert( r2.result+'\n'+r2.datetime + " \n"+Engine.connectInfo() )
            return true;
        }catch(err){
            alert("ERROR " + err.message+"  \n"+Engine.connectInfo()) 
            return false

        }
    }


    static async postData(url, data = {}) {
        try{ 
            if(!data) data = {}
            data.token = appData.getToken()
            //console.log("engine postData token:",data.token)            
            data.version = appData.getVersion()
            // Default options are marked with *
            const opt = {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // 'cors' no-cors, *cors, same-origin 
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                //     "Access-Control-Allow-Origin": "*",
                //    "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept",
                //    "Access-Control-Allow-Methods": "GET, POST"


                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            }            
            const response = await fetch(SERVER + url, opt);
            //const response = await fetchWithTimeout(SERVER + url,opt , 30000)
            let rez =  await response.json(); // parses JSON response into native JavaScript objects
            if(rez.result === 'Error' && rez.errcode === 5){
                alert('Потрібно перереєструватися ...')
                appData.data.userName = false
                rez = false
            }else{
                if(rez.result === 'Error' && rez.errcode === 3){
                    alert('Потрібно ОНОВИТИ ВЕРСІЮ ...')
                    appData.data.userName = false
                    rez = false
                }
                else if(rez.result === 'Error'){
                        alert("Error code: "+rez.errcode+" error: "+rez.errorTxt)
                        rez = false
                }
            }

            return rez
        } catch(err){
            alert("ERROR :: " + err.message) 
            return false //{'result':'error','errcode' : 9999,'Error' : err.message}

        }
      }



}


const HookPostData = (url, dataPost = {}) =>{
    const [data,setData] = useState()
    const [error,setError] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        if(!url) return
        fetch(SERVER + url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(dataPost) // body data type must match "Content-Type" header
        }).then(data => data.json())
        .set(setData)
        .then(()=>setLoading(false))
        .catch(setError)
    },[url,dataPost]);
    return{ loading,data,error  }

  }

export default Engine
