import React from "react";
import { dateTimeStrToStr } from "./ComFunc";


export const LineIn1 = (props) => {
    const p = props.p
    const v = dateTimeStrToStr(props.v)
    let r = ' '
    //if(p.trimLeft()) r = ': '
    if(p) r = ': '
    if(p) return (<> {p} {r}<b>{v}</b></>)
    return (<><b>{v}</b></>)
}