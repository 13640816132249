/*
Поле вводу, 
ПРОПси:
fname - ім'я поля
ftype,fcaption,fhint,
fval, - початкове значення
fvals, - для switch
fonChange - при зміні в полі викликається ця функція, це основний засіб отримання нових значеннь
      сігнатура 
      const onChange = event =>{
        if(event.target.name) setValues({...values,[event.target.name]:event.target.value})
      }  
      тобто назад передаємо event.target.name - ім'я поля  event.target.value - значення
,fnotNull - чи є "рекваіред"
,msg - повідомлення "під"
,errmsg - червоне повідомлення "під"
,href - андер констрактіон
,datalist  - для ftype === 'text' варіанти підказки
,grid=0, - як розміщувати підпис fcaption та саме поле вводу
      0 - один під іншим
      1 - в строку (при можливості) fcaption - "коротний" інпут - "довгий"
      2 - в строку (при можливості) fcaption - "довгий" інпут - "короткий"
key

типи вводу що підтримуються
'text' 'password' 'email''date' 'number'
switch - "стильний" перемикач ліворуч, підпис праворуч
checkbox - ліворуч підпис, праворуч "галочка" яка вирівняна по правіому краю
select - вибір з fvals
line - розділька лінія
info - просто fcaption





*/


import React from "react";
import { FormGroup,FormFeedback,FormText,Label,Input,Col, Button  } from "reactstrap";

import Emo from "./Emo";
//const intputTypes = ['text','password']

const Input0 = props =>{
    const {fname,ftype,fcaption,fhint,fval,fvals,fonChange,fnotNull,msg,errmsg,href,datalist,grid=0,key} = props
    const [checkBoxState,setCheckBoxState] = React.useState(fval?true:false)
    const toggleCheckBoxState = () => {
      setCheckBoxState(!checkBoxState)
      if(ftype === 'checkbox' || ftype === 'checkboxLeft'){
        let v = checkBoxState ? 0:1
        let e = {target:{value:v,name:fname}}
        fonChange(e)
      }
    }
    const checkBoxStateFun = (event) => { //для ftype === 'switch' робимо адекватне value
      event.target.value = event.target.checked ? 1:0
      fonChange(event)
    }
    let params = {}
    if((ftype === 'text') || (ftype === 'password')  || (ftype === 'email') || 
       (ftype === 'date') || (ftype === 'number') || (ftype === 'textarea')){        
        if (fnotNull) params = {'required' : true}
        if (errmsg) params['invalid'] = true
        if (href) {params['href'] = href;}
        return (
            <>
            <Input {...params}  list={fname} type={ftype} name={fname} placeholder={fhint} onChange={fonChange} defaultValue={fval} />
            {datalist && <datalist id={fname}>
              {datalist.map((v,i) => <option value={v}></option>)}
              </datalist>}
            </>
        )
    }
    if(ftype === 'date') {        
      if (fnotNull) params = {'required' : true}
      if (errmsg) params['invalid'] = true
      return (
          <Input {...params}  type={ftype} name={fname} id={fname} placeholder={fhint} onChange={fonChange} defaultValue={fval} />
      )
    }    
    if(ftype ===  'switch'){
      //if(fval) setCheckBoxState(true)
      //className="flex-row-reverse gap-1"
      return (
        <Input type="switch"  name={fname} checked={checkBoxState} onClick={() => { setCheckBoxState(!checkBoxState)}} onChange={checkBoxStateFun}/>        
      )
      }
      if( ftype === 'checkbox'){
        //<Button sm={6} color="link" outline onClick={toggleCheckBoxState}><strong>{fcaption}</strong></Button>
        return (
          <>
            {checkBoxState && <span style={{float:"right"}} onClick={toggleCheckBoxState}>{Emo.Ok1()}</span>}
            {!checkBoxState && <span style={{float:"right"}} onClick={toggleCheckBoxState}>{Emo.Empty1()}</span>}
            {msg && (<div className="text-center"><FormText>{msg}</FormText></div>)}
         </>
        )
    }
    if( ftype === 'checkboxLeft'){
      //<Button sm={6} color="link" outline onClick={toggleCheckBoxState}><strong>{fcaption}</strong></Button>
      return (
      <FormGroup  style={{margin:"1px 2px 1px 3px"}}  >
          <table>
            <tr>
              <td style={{width:"2rem"}}>
                {checkBoxState && <span  onClick={toggleCheckBoxState}>{Emo.Ok1()}</span>}
                {!checkBoxState && <span  onClick={toggleCheckBoxState}>{Emo.Empty1()}</span>}
              </td>
              <td>
                <Label for="{name}" style={{fontSize:"20px"}}  onClick={toggleCheckBoxState}><strong>{fcaption}</strong></Label>

              </td>
            </tr>
          </table>
      </FormGroup>
      )        
    }
    if(ftype === 'select'){
      return(
          <Input {...params}  type={ftype} name={fname} placeholder={fhint} onChange={fonChange}>
            {fvals.map((v)=>{
                if(v===fval) return <option selected="selected">{v}</option>
                return <option>{v}</option>
              }
             )}
          </Input>    

      )
    }
    if((ftype === 'line')){    
       return ( <React.Fragment><hr /></React.Fragment> )
    }
    if(ftype == 'info' || !ftype){
      return(
          <strong><Label>{fval}</Label></strong>         
      )      
    }
    return ( <React.Fragment><br />Type {ftype} not supported...<br /></React.Fragment> )
}

export default Input0