//import logo from './logo.svg';
import React from 'react';

import Pages from './pages';

function App() {
  return (
      <Pages />
  );
}

export default App;
