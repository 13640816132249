import React from "react";
import { Button } from "reactstrap";
import {usePosition} from '../comcom/geo1'
import ShowGMap1 from '../comcom/GMap1'
import appData from "../AppData";

import RedirectToLogin from '../components/RedirectToLogin'

const PageTestCoordinat = props => {
    const [ifRun,setRun] = React.useState(false)
    const [ifMap,setMap] = React.useState(false)
    const { latitude, longitude, wait, error } = usePosition();
    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )
    const btnClick = ()=> setRun(!ifRun)
    const btnMapClick = ()=> setMap(!ifMap)
    const points = [
               {lat: latitude, lng: longitude, title : 'Ви знаходитесь тут',label : 'Ви тут'},
    ]
    
    return (
        <div>
            <p>Тест координат</p>
            <p>
            <Button color="primary" outline onClick={btnClick}> Запуск/Зупинити </Button>
            </p>
            {!ifRun && <p>Запустіть тест</p>}
            {ifRun && <div>
                {wait && <div>Хвилинку ...</div>}
                {error && <div>ПОМИЛКА:{error}</div>}
                {latitude && <div>
                     latitude, longitude: {latitude}, {longitude} <br /><br />
                     <Button color="primary" outline onClick={btnMapClick}> Показати на мапі </Button><br/><br/>
                </div>
                }
               </div>            
            }
            {ifMap&& <ShowGMap1 points={points}/> }
        </div>        
    )
}

export default PageTestCoordinat