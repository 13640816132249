import React from "react";
import appData from "../AppData";

const usePageDataCashed1 = function(CASH_TAG = undefined, functLoadData = undefined, funcTestData = undefined){
    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
    if(!funcTestData){
        funcTestData = function(d){
            if(!d) return false;
            if(!d.IDS) return false;
            if(!appData) return false
            if(!appData.data) return false
            if(!appData.data.IDS) return false
            return d.IDS=appData.data.IDS
        }  
    }
    React.useEffect( 
        () => {
              if(appData.loginOk())  
                fLoadData(true)
            }, [] 
    )

    if (CASH_TAG == undefined || functLoadData == undefined ) 
        return {data:false,err : 'no params'};


    const fLoadData =  async function( canUseCash = true, param){
        //canUseCash = false;
        if(canUseCash){
            try{
                let wd = localStorage.getItem(CASH_TAG)
                if(wd) {
                    wd = JSON.parse(wd)
                    if(wd && funcTestData(wd)){
                        setData( wd.data )
                        return    
                    } else {
                        canUseCash = false;
                    }
                }else{
                    canUseCash = false;
                }
            }catch(e){
                canUseCash = false;
            }
        }
        if(!canUseCash){
            try{
                try{
                    setloading(true)
                    setData( false )
                    let wd = await functLoadData(param)
                    if(wd && appData && appData.data && appData.data.IDS){ 
                        //wd.IDS = appData.data.IDS
                        saveDataToCash(wd)
                    }
                    setData( wd )
                }catch(err){
                    setErr(err)
                }
            }finally{
                setloading(false)
            }
        }
        setloading(false)
    }
    const fReloadData =  async function(param){
        await fLoadData( false,param )
    }

    const saveDataToCash = (wd = undefined) =>{
        if (!wd) {wd = {}; wd.data = wd}
        wd.IDS = appData.data.IDS
        wd = {IDS : appData.data.IDS, data : wd}
        localStorage.setItem(CASH_TAG,JSON.stringify(wd))
     }

     
     const setNewData = (newData) =>{
        setData(newData)
        saveDataToCash( newData )
     }
     return [data,loading,err,fReloadData,setNewData,setloading]

}


export default usePageDataCashed1