import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import Engine from "../engine/engine";

const RedirectToLogin = props =>{
    const testConnToServer = async () => await Engine.testSimpleConnectToServer()
    return (
        <p>
            <b>Внутрішній ресурс компанії Мові-Хелс</b>
            <br />
            Для продовження зареєструйтесь за посилянням
            {'   '}<Link to={'/login'}>РЕЄСТРАЦІЯ</Link>
            <br />
            <br />
            <br />
            <br />
            Для перевірити доступність сетвера натисніть:<Button color="link" onClick={testConnToServer}>test</Button> 
        </p>
    )
}

export default RedirectToLogin