import React from "react";

import { CachingTag } from "../../WTConst";
import usePageDataCashedWT1 from "../PageDataCashedWT1";
import Table1AndFilter from "../../comcom/Table1AndFilter";
import Eng001 from "../../engine/eng001";


const TableMonth = props =>{

    const yy = (new Date()).getFullYear() 
    //const mm = (new Date()).getMonth()+1

    const loadData = async (p) => {
       
        if(!p.YY || !p.MM) {
            //alert('Вкажіть рік та місяць')
            return false
        }
        return await Eng001.loadTableMonth(p)
        
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.Tabel_2,loadData)
    const fieldsFilterForm = [
        {fname:'DISTR',ftype :'text',fcaption:'Регіон',fhint:'',fnotNull:false,fval:''},
        {fname:'OBL',ftype :'text',fcaption:'Обл',fhint:'',fnotNull:false,fval:''},
        {fname:'MP',ftype :'text',fcaption:'МП',fhint:'',fnotNull:false,fval:''},
        {fname:'YY',ftype :'select',fcaption:'Рік',fhint:'',fnotNull:false,fval:yy,fvals:[yy-1,yy]},
        {fname:'MM',ftype :'select',fcaption:'Місяць',fhint:'',fnotNull:false,fval:'',fvals:['',1,2,3,4,5,6,7,8,9,10,11,12]},
        ]
    const Theaders = ['Регіон','Обл','МП','Посада','Працює?']
    const Tfields = ['DISTRICT','OBL','NAME1','SOTRTP','IFWORK']
    for(let i=1;i<=31;i++){Theaders.push(i);Tfields.push('D'+i)}
    return <>
        <Table1AndFilter 
                    formHeader={'Табель'} 
                    fieldsFilterForm = {fieldsFilterForm}
                    loadDataFromServer = {funLoadData}
                    Theaders = {Theaders} 
                    Tfields = {Tfields} 
                    filterOpt="1" 
                    data = {data}
                    loading = {loading}
                    err ={err}                    
                />    
            </>
}


export default TableMonth


/*
                    fieldsForAction={"ORGNAME"}
                    actionInTable={ActionInTable} 
                    currRowInTable={currRowInTable}    
                    setCurrRowInTable={setCurrRowInTable}

                    localStorageTag = {CachingTag.VizitsMPFORM}
                    tableLineButton = {tableLineButton}
                    tableLineButtonFun = {buttonProc}
                    tableLineButtonFunKeyFld={'ID'}


*/