import React from "react";
import { Button } from "reactstrap";


import appData from "../AppData";
import { CachingTag } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";

import RedirectToLogin from '../components/RedirectToLogin'
import Eng001 from "../engine/eng001";
import Wait1 from "../comcom/Wait1";


const PageInstructions = (props) =>{
    const loadData = async () =>{
        try{
            let d = await Eng001.loadInstructions()
            return d
        }catch(err){
            return false
        }
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.Instructions,loadData)


    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )    
    if(loading) return <Wait1 />
    if(!data) return <Wait1 />
    return <>
        <Button color="primary" outline onClick={funLoadData}>Оновити</Button>
        <h5>Документи, інструкції</h5>
            
        {data && <>
            {data.map((d)=><p>
                <a href={d.HREF} >{d.LABEL}</a>
                
            </p>)}
        </>}

    </>
}

export default PageInstructions