import React from "react";
import { Spinner } from "reactstrap";

const Wait1 = (props) =>{
    const msg = props.msg?props.msg:"Хвилинку..."
    return (  <div>
             {msg && (<h6>{msg}</h6>)}
             <Spinner>
              Loading...
             </Spinner>
            </div>)
}



export default Wait1