import React from "react";
import { useNavigate } from "react-router-dom";
import { Button,Collapse,
    Card,CardBody,CardHeader,CardTitle,CardText
} from "reactstrap";




import { CachingTag } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";
import Form1 from "../comcom/Form1";
import Emo from "../comcom/Emo";
import { dateStrWEB,cloneObj } from "../comcom/ComFunc";
import Eng001 from "../engine/eng001";
import Wait1 from "../comcom/Wait1";
import { RoutPath } from "../WTConst";
import UsgPrev from "../components/MainForm/UsgPrev";

const PageWtDocList = props =>{
    const navigate = useNavigate()
    const [fform,showFform] = React.useState(false)
    const toogleFForm = () => showFform(!fform)
   
    const [fFilter,setFfilter] = React.useState({DFROM : dateStrWEB(new Date(),-2)})


    const loadData = async (p) => {
        try{
            if(p) setFfilter(p)
            else p = fFilter
            if(!p.DFROM) {
                p.DFROM = dateStrWEB(new Date(),-1)
                setFfilter(p)
            }
            let d = await Eng001.loadDocList(p)
            return d
        }catch(e){
   
            return false;
        }
    }
    const funcTestData = () => { return true}
    
    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.Uzgodg1,loadData,funcTestData)
    //React.useEffect(()=>{ funLoadData() },[])
    


    const setFilter = ( p ) => {
        if(p && p.formData){
            p = p.formData
        }else{
            p = {DFROM : dateStrWEB(new Date(),-5)} //-1
        }
        setFfilter(p)
        showFform(false)
        funLoadData(p)
        
    }

    const delDoRozgladu = (IDDOC) =>{
        let data_tmp = cloneObj(data)
        for(let i=0;i<data.length;i++){
            if(data_tmp[i]['IDDOC'] == IDDOC) data_tmp[i]['ALARM'] = ''
        }
        setNewData(data_tmp)

    }
  
    const fields = [
        {fname:'DFROM',ftype :'date',fcaption:'Дата з',fhint:'',fnotNull:false,fval:fFilter.DFROM},
        {fname:'DTO',ftype :'date',fcaption:'До',fhint:'',fnotNull:false,fval:''},
        {fname:'TYPE',ftype :'text',fcaption:'Тип',fhint:'',fnotNull:false,fval:''},
        {fname:'USER1',ftype :'text',fcaption:'Ініціатор',fhint:'',fnotNull:false,fval:''},
        {fname:'IDDOC',ftype :'number',fcaption:'Номер',fhint:'',fnotNull:false,fval:''},
        //{fname:'TXT',ftype :'text',fcaption:'Текст',fhint:'',fnotNull:false,fval:''},
        ]
    const formCFG = {
        caption : "",
        buttonCaption : "Пошук",
        cancelBtnCaption : 'Відміна',
        fields : fields,
        action : setFilter,
        actionCancel : toogleFForm,
        clearData : false,
        localStorageTag : CachingTag.Uzgodg1_F,
        } 
        return <>
        <h5>Узгодження</h5>
                <Button color="link"  outline onClick={toogleFForm}>{Emo.Look1()}{'Пошук'}</Button>  
                <Button color="link" style={{float:'right'}} outline onClick={setFilter}>{Emo.Cicle2()}{'Останні'}</Button>  
                
                <Collapse isOpen={fform}>
                    <Card>
                        <CardBody>
                            <Form1 formCFG={formCFG}  />                
                        </CardBody>
                    </Card>
                </Collapse>
                {loading && <Wait1 />}
                {data && <>
                    <hr />Вибрано : {data.length} узгоджень
                    {data.map((d,i)=>
                        <UsgPrev d = {d} delDoRozgladu={delDoRozgladu}/>
                    )}
                    </>
                }
                </>
}

export default PageWtDocList