import React from "react";
import { useLocation } from "react-router-dom";

import appData from "../AppData.js";
import Eng001 from "../engine/eng001.js";

import RedirectToLogin from '../components/RedirectToLogin.js'
import usePageDataCashedWT1 from "../components/PageDataCashedWT1.js";
import Table1AndFilter from "../comcom/Table1AndFilter.js";
import Doctor2 from "../components/Doctor/Doctor2.js";
import {CachingTag} from "../WTConst.js"

const PageDoctor1 = props => {
    const [ID,setID] = React.useState(0)
    const [currRowInTable,setCurrRowInTable] = React.useState(0)
    const location = useLocation();
    let pAdd = location.state;     
    if(pAdd == null) pAdd = false

    const loadData = async (filterData) =>{
        if(!filterData.NAME1 &&  !filterData.ORGNAME && !filterData.ADR && !filterData.MP && !filterData.OBL){
            alert('Задайте параметри пошуку...')
            return false
        }        
        try{
            let d = await Eng001.getDoctor1(filterData)
            setCurrRowInTable(0)
            return d
        }catch(err){
            return false
        }
    }
    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.DoctorsCash,loadData)
  
    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )

    const buttonProc = async (IDORGSOTR) => {
        if(pAdd.TP==='ADDTOWORK'){
            let p = {IDSOTR:pAdd.IDSOTR,DD:pAdd.DD,IDORGSOTR:IDORGSOTR}
            //alert(IDORGSOTR)
            let r = await Eng001.addDoctorToVizit(p)
        }
    }
    let tableLineButton = ''
    if(pAdd) tableLineButton = 'Додати'


    const fieldsFilterForm = [
        {fname:'NAME1',ftype :'text',fcaption:'ПІБ лікаря',fhint:'ПІБ лікаря',fnotNull:false,fval:''},
        {fname:'ORGNAME',ftype :'text',fcaption:'ЛПЗ',fhint:'Назва',fnotNull:false,fval:''},
        {fname:'ADR',ftype :'text',fcaption:'Адреса',fhint:'Адреса',fnotNull:false,fval:''},
        {fname:'VRACHSPEC',ftype :'text',fcaption:'Спеціальність',fhint:'Спеціальність лікаря',fnotNull:false,fval:''},
        {fname:'OBL',ftype :'text',fcaption:'Область',fhint:'',fnotNull:false,fval:''},
        {fname:'IFWORK',ftype :'select',fcaption:'Працюючі',fhint:'',fnotNull:false,fval:'Y',fvals:['Y','N',' ']},
        {fname:'MP',ftype :'text',fcaption:'Закріплення',fhint:'Закріплення',fnotNull:false,fval:''},
    ] 
    const Theaders = ['Лікар','Спеціальність','ЛПЗ','Адреса','Закріплення','ABC','Ост.візит']
    const Tfields = ['NAME1','VRACHSPEC','ORGNAME','ADR','ZAKR_MP','ABC','DTVIZIT']

    const ActionInTable = (row,fn,rowNN) => {
        let idorgsotr_ = row['IDORGSOTR']
        if(idorgsotr_ && idorgsotr_>0) setID(idorgsotr_) 
        setCurrRowInTable(rowNN)
        //rowNN_ = rowNN
    }
    const hideDetail = () => setID(0)
    if (ID>0){
        return <Doctor2 IDORGSOTR={ID} funcBack={hideDetail}/>
    }
    
    return (
        <>
        <Table1AndFilter 
            formHeader={'Лікарі'} 
            fieldsFilterForm = {fieldsFilterForm}
            loadDataFromServer = {funLoadData}
            Theaders = {Theaders} 
            Tfields = {Tfields} 
            fieldsForAction={"NAME1"}
            actionInTable={ActionInTable} 
            filterOpt="1" 
            currRowInTable={currRowInTable}    
            setCurrRowInTable={setCurrRowInTable}
            data = {data}
            loading = {loading}
            err ={err}
            localStorageTag = {CachingTag.DoctorsFormFilter}
            tableLineButton = {tableLineButton}
            tableLineButtonFun = {buttonProc}
            tableLineButtonFunKeyFld={'IDORGSOTR'}            
        />
        </>        
    )
}

export default PageDoctor1








