/*
"Історичні" візити мп, для вибору при створенні двійних і тп візитів
*/
import React from "react";
import appData from "../AppData";
import { CachingTag } from "../WTConst";
import  usePageDataCashedWT1  from "../components/PageDataCashedWT1";

import {NowDateToStr} from '../comcom/ComFunc'
import RedirectToLogin from '../components/RedirectToLogin'
import Table1AndFilter from "../comcom/Table1AndFilter";
import Eng001 from "../engine/eng001";
import Work2d from "../components/Work2/Work2d";



const PageWork2 = props => {
    
    const loadData = async (ff) =>{
       
        if(!ff.DWORK) {
            ff.DWORK = NowDateToStr()
            //return false
        }
        try{
            let d = await Eng001.loadWork2(ff)
            for(let i=0;i<d.length;i++) d[i]['MA'] = d[i]['MA'].substr(11,5)
            return d
        }catch(err){
            return false
        }
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.Work2,loadData)
    const [currRowInTable,setCurrRowInTable] = React.useState(0)
    const [sotrDay,setSotrDay] = React.useState(false)

    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )
    const ActionInTable = (row,fn,rowNN) => {
     
        setSotrDay( row )
    }    
    if(sotrDay){
        return <Work2d user={sotrDay} proc={setSotrDay} />
    }

    const buttonProc = async (id) => {
    }

    const fieldsFilterForm = [
        {fname:'OBL',ftype :'text',fcaption:'Обл',fhint:'',fnotNull:false,fval:''},
        {fname:'DWORK',ftype :'date',fcaption:'Дата',fhint:'',fnotNull:false,fval:''},        
        {fname:'PIB',ftype :'text',fcaption:'ПІБ',fhint:'',fnotNull:false,fval:''},
    ] 
    const Theaders = ['ПІБ','Посада','Таб','Кільк робіт','Ост.оп.','Обл']
    const Tfields = ['NAME1','SOTRTP','METKA','KOLJ','MA','OBL2']

    let tableLineButton = ''

    return (
        <>
        <Table1AndFilter 
            formHeader={'Роботи співробітників'} 
            fieldsFilterForm = {fieldsFilterForm}
            loadDataFromServer = {funLoadData}
            Theaders = {Theaders} 
            Tfields = {Tfields} 
            fieldsForAction={"NAME1"}
            actionInTable={ActionInTable} 
            filterOpt="1" 
            currRowInTable={currRowInTable}    
             setCurrRowInTable={setCurrRowInTable}           
            data = {data}
            loading = {loading}
            err ={err}
            localStorageTag = {CachingTag.VizitsMPFORM}
            tableLineButton = {tableLineButton}
            tableLineButtonFun = {buttonProc}
            tableLineButtonFunKeyFld={'IDNCONTR'}
            
        />            
        
        </>        
    )
}

export default PageWork2