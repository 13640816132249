/*
                <Table1 headers = {Theaders} 
                        fields = {Tfields} 
                        initData = {data} 
                        fieldsForAction={fieldsForAction}
                        action={actionInTable} 
                        filterOpt={filterOpt} 
                        currRowInTable={currRowInTable}
                        tableLineButton={tableLineButton}
                        tableLineButtonFun={tableLineButtonFun}
                        tableLineButtonFunKeyFld={tableLineButtonFunKeyFld}
                        />

*/
import React, { useState } from "react";
import { Table,Input,Button,ModalBody,Modal,Spinner  } from "reactstrap";

import {dateTimeStrToStr} from './ComFunc'

function clone(o){
  return JSON.parse(JSON.stringify(o))
}

const Table1 = props =>{
    let {headers,initData,fields,action,filterOpt,fieldsForAction,
          tableLineButton,tableLineButtonFun,tableLineButtonFunKeyFld,
         currRowInTable,align=''} = props

    const [sorting,setSorting] = React.useState({column:null,descending:false,})
    const [data,setData] = React.useState(initData)
    const [filterStr,setFilterStr] = React.useState(false)
    const [modalWait, setModalWait] = useState(false);
    //const [currRow,setCurrRow] = React.useState(0)
    let sData = data

    if(!action) action = () => {}
//     || !keyFld){action = undefined; keyFld=undefined}

    

    function sort(e){
      return;
      const fn = fields[e.target.cellIndex]
      const descending = sorting.fn === fn && !sorting.descending
      const dataCopy = clone(data)
      dataCopy.sort((a,b)=>{
        if(a[fn]==b[fn]){return 0}
        return descending ? a[fn] < b[fn]? 1 : -1
                          : a[fn] > b[fn]? 1 : -1
      })
      setData(dataCopy)
      setSorting({fn,descending})
    }
    function onSearch(e){
      setFilterStr(e.target.value)
    }
    function RowAction(e){
      
      let tg = e.target
      let fldIndex = tg.cellIndex-1
      if(isNaN(fldIndex)){
        tg = tg.parentNode
        fldIndex = tg.cellIndex-1
      }

      if(!fieldsForAction || fieldsForAction.includes(fields[fldIndex]))  
          action(sData[tg.parentNode.rowIndex-1], fields[fldIndex],tg.parentNode.rowIndex)
    }

    async function BtnAction(e){
      let tg
      try{
          tg = e.target.parentNode.parentNode

          let id
          try{
            id = sData[tg.rowIndex-1][tableLineButtonFunKeyFld]
          }catch(err){
            try{
              tg = tg.parentNode
              id = sData[tg.rowIndex-1][tableLineButtonFunKeyFld]
            }
            catch(err){
              tg = tg.parentNode
              id = sData[tg.rowIndex-1][tableLineButtonFunKeyFld]

            }  

          }
          setModalWait(true)
          await tableLineButtonFun( id)
          setModalWait(false)
  
      }catch(err){

      }
    }


    function goToRow(nRow){
      const gotoRow = document.getElementById("ROW"+nRow)
      if( gotoRow){
        gotoRow.scrollIntoView({ block: "center", behavior: "auto" })
      }

    }
    function goToUp(){
      goToRow(1)
    }

  React.useEffect(()=>{
      if (currRowInTable>0){
        goToRow(currRowInTable - 1)
        currRowInTable = 0
      }
    }
  ) 


  if(!Array.isArray(data)){      return <>no data...</>    }

    sData = data
    function formatFielsData(fn,val){
      return  dateTimeStrToStr(val)
    }



    if (filterStr) {
      sData = []
      const needle = filterStr.toLowerCase();
      let ifOk = false
      for(let i=0;i<data.length;i++){
          ifOk = false
          for (let f = 0; f < fields.length; f++) {             
             if (data[i][fields[f]] && data[i][fields[f]].toString().toLowerCase().includes(needle)) {
              ifOk = true
          }
        }
        if(ifOk) sData.push(data[i])
      }
    }

    if(align == 'R') align = {float : 'right'}
    else align = {}
    return ( 
      <div>
        {filterOpt && <Input type="text" placeholder='Пошук/Фільтр' name="filterStr" id="filterStr"  onChange={onSearch} />}

        <Table className="table table-striped table-hover table-sm">
            <thead onClick={sort}>
              <tr className="table-secondary">
              <th></th>
                {headers.map((capt,i)=>{
                    if(sorting.fn && sorting.fn === fields[i+1] ){
                      capt += sorting.descending ? ' \u2191' : ' \u2193'
                    }
                    return<th>{capt}</th>
                  })
                }
              </tr>
            </thead>
            <tbody onClick = {RowAction}>
              {sData.map((rowData,rid)=>{
                    return (
                      <tr>
                        {!tableLineButton && <td><div id={"ROW"+rid}>{rid+1}</div></td>}
                        {tableLineButton && <td><Button color="primary" outline onClick={BtnAction}>{tableLineButton}</Button> </td>}
                        {fields.map((fn,fid) =>{
                          const vv = formatFielsData(fn,rowData[fn])
                          const hrefFormat = fieldsForAction && fieldsForAction.includes(fn)
                          if(hrefFormat) return <td><u>{vv}</u></td>
                          if(fid==0)return <td><span>{vv}</span></td>
                          return <td><span style={align}>{vv}</span></td>}
                        )}
                      </tr>
                      )//return (
                    })}
            </tbody>
        </Table>
        {sData.length>100 && <Button color="primary"  outline onClick={goToUp}>{'\u2191'} Нагору</Button> }
        <Modal isOpen={modalWait} size="sm">
            <ModalBody>
              Хвилинку ...
            </ModalBody>
        </Modal>
      </div>
     )
}

export default Table1

/*
<Spinner  className="m-5"  color="primary">  Loading...</Spinner>
                        {fields.map((fn,fid) =><td>{ 
                          !iifHref() && formatFielsData(fn,rowData[fn]) 
                        } </td>)}

goToRow(1)
<a name={"ROW"+rid}></a></td>

                    if (filterStr) {
                      const needle = filterStr.toLowerCase();
                      let match = false;
                      for (let f = 0; f < fields.length; f++) {
                        if (rowData[fields[f]] && rowData[fields[f]].toString().toLowerCase().includes(needle)) {
                          match = true;
                        }
                      }
                      if (!match) {
                        return null;
                      }
                    }

*/