//короткі відомості про узгодження

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card,CardBody,CardHeader,CardText} from "reactstrap";
import { dateStrWEB,dateTimeStrToStr1 } from "../../comcom/ComFunc";
import { RoutPath } from "../../WTConst";
import Emo from "../../comcom/Emo";
import Eng001 from "../../engine/eng001";

const UsgPrev = ({d,delDoRozgladu}) =>{
    const navigate = useNavigate()
    let hStyle = {fontWeight:'bold'}
    if(d.ALARM!='') hStyle = {fontWeight:'bold',color:'red'}

    const delDoRozgladu_ = async() =>{
        if (window.confirm('Зняти "до розгляду"?')){
            let r = await Eng001.deleteDocAlarm({IDDOC : d.IDDOC})
            if(r){
                if(delDoRozgladu) delDoRozgladu(d.IDDOC)
                d.ALARM = ''
            }
            
        }
    }

    return <>
        <Card>
            <CardHeader  style={{cursor:"pointer"}} 
                onClick={()=>{
                    let p = {IDDOC : d.IDDOC}
                    navigate(RoutPath.WTDOC,{state : p})
                }}>
                #{d.IDDOC} {' '} <span style={hStyle}>{d.DOCTXT1}</span>
                
            </CardHeader>
            <CardBody>
            <CardText>
                        Тип : <b>{d.DOCTP}</b>
                        {d.ALARM && <>{' '}<Button  size="sm" outline style={{float:'right'}} onClick={delDoRozgladu_}>{Emo.Cancel1()}</Button></>}                        
                <br />  Статус : <b>{d.DOCSTATUS} </b>        
                <br />  Створив : <b>{d.NAME1SCREATE.substr(0,15)} {' '} {dateTimeStrToStr1( d.DTCREATE )}</b>
                <br />  Редагував: <b>{d.LASTEDIT.substr(0,15)} {' '} {dateTimeStrToStr1( d.DTMODI )}</b>
                <br />  Текст: <b>{d.TXTLAST}...</b>
                <br />
                
            </CardText>
            </CardBody>                    
        </Card>
    
    
    </>
}


export default UsgPrev