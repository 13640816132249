import React from "react";
import {Container,Col,Row} from 'reactstrap'

const CenterLayout = props =>{
    return (
    <Container>
        <Row xs="3">
            <Col className="col-md-3 col-sm-1 col-1 "> </Col>
            <Col className="col-md-6 col-sm-10 col-10">
                {props.children}
            </Col>
            <Col className="col-md-3 col-sm-1 col-1"></Col>
        </Row>
    </Container>        
    )
}

export default CenterLayout