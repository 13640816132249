import React from "react";
import { Button,Collapse, CardBody, Card } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger,FormatTXT } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"

export const NetShemottshnic = (props) => {
    const IDNCONTR = toInteger(props.IDNCONTR)
    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(true)

    const loadData = async ()=>{
        setloading(true)
        setData( false )
        setErr( false)        
        let d = await Eng001.loadShemotehnica1({IDNCONTR:IDNCONTR})
        if(d){
            setloading(false)
            setData( d )
        } else {
            setloading(false)
            setErr( true)        
        }
    }
    const toggle = () => setIsOpen(!isOpen);
    let TXTSHMT = '' //FormatTXT(data[0]['F13'])
    if(data && data.length>0) TXTSHMT = FormatTXT(data[0]['F13'])
    return <>
        {loading && <Wait1 />}
        {err && <Error1 />}
        {!data && !loading && <Button  outline on onClick={loadData}>Схемотехніка...</Button>}
        {data && data.length>0 && <div>
                    <Button  outline onClick={toggle} >Схемотехніка за {data[0]['MM']}/{data[0]['YY']}</Button>
                    <Collapse isOpen={isOpen}>
                        <Card>
                            <CardBody>
                            
                            <div dangerouslySetInnerHTML={{ __html: TXTSHMT }} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
        }
    </>
}