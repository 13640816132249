import React from "react";
import appData from "../AppData";
import { Button } from "reactstrap";
import { useLocation,useNavigate } from "react-router-dom";

import { RoutPath,remoteDayWorkCash } from "../WTConst";
import {dateTimeStrToStr} from "../comcom/ComFunc";
import Emo from "../comcom/Emo";
import { usePageData1 } from "../comcom/PageData1";
import Eng001 from "../engine/eng001";
import ShowGeoPosition from "../components/ShowGeoPosition";

import {usePosition} from '../comcom/geo1'
import RedirectToLogin from '../components/RedirectToLogin'
import Wait1 from "../comcom/Wait1";
import Form1 from "../comcom/Form1";

const PageKrStil = props => {
    const location = useLocation();
    let param = location.state;     
    const IDKRSTOL = param.IDWORK
    const { latitude, longitude, wait, error } = usePosition();
    const navigate = useNavigate()
    const goToWorkPage = () => {
        remoteDayWorkCash()
        navigate(RoutPath.Work1)
    } 

    const saveData = async (d) => {
        let p = d.formData
        p.latitude = latitude
        p.longitude = longitude
        p.IDKRSTOL = IDKRSTOL
        let r = await  Eng001.saveKR_STOL2( p )
        goToWorkPage()
    }
    

    const loadData = async () => {
        const d = await Eng001.loadKR_STOL2({IDKRSTOL})
        if(Array.isArray(d)) return d[0]
        return false
    }
    const [data,loading,err,fLoadData,setNewData,setloading] =  usePageData1(loadData)

    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )


    const fields = [
        {fname:"v1",ftype:"info",fcaption:"Дата",fval:dateTimeStrToStr(data['DD_PLAN'])},    
        {fname:"v2",ftype:"info",fcaption:"Тип",fval:data['KRSTOLTP']},    
        {fname:"v3",ftype:"info",fcaption:"Співробітник",fval:data['NAME1']},    
        {fname:"v4",ftype:"info",fcaption:"Організація",fval:data['ORGNAME']},    
        {fname:"v5",ftype:"info",fcaption:"Адреса",fval:data['ADR']},            
    ] 
    if( data['VR']){
        fields.push({fname:"v6",ftype:"info",fcaption:"Лікар",fval:data['VR']})
        fields.push({fname:"v7",ftype:"info",fcaption:"Спеціальність",fval:data['VRACHSPEC']})
    }
    fields.push({fname:"v8",ftype:"info",fcaption:"Запл.витрати",fval:data['ZATR_PLAN']})
    fields.push({fname:"v9",ftype:"info",fcaption:"Стан",fval:data['SOSTCOMMENT']})
    fields.push({fname:"COMMENT_FAKT",ftype:"text",fcaption:"Тема",fval:data['COMMENT_FAKT']})
    fields.push({fname:"KOL_PREZENT",ftype:"number",fcaption:"Кількість присутніх",fval:data['KOL_PREZENT']})
    fields.push({fname:"ZATR_FAKT",ftype:"number",fcaption:"Фактичні витрати",fval:data['ZATR_FAKT']})

    const formCFG = {
        caption : "Круглі столи",
        buttonCaption : "Зберегти",
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : saveData,
        grid : 1,
    } 

    if(loading)
        return <>
        <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button> 
        {'  '}
        {loading && <Wait1 />}
        </>

    return (
        <>
        <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button> 
        {'  '}
        <br />
            <Form1 formCFG={formCFG}  />
            <ShowGeoPosition latitude={latitude} longitude ={longitude} wait={wait} error={error} />
        </>
            
    )
}

export default PageKrStil