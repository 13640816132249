//визначення місцезнаходження з використанням АПІ брацзера
//оформлено як ХУК
//ідея на базі https://habr.com/ru/articles/458614/
//import { usePosition } from "./geo1";
//  const { latitude, longitude, wait, error } = usePosition();
//  return (<div>Test ... <br />wait:{wait},latitude:{latitude},longitude: {longitude},error:{error}</div>  );
//}
import { useState, useEffect } from 'react'

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = (geoRez) => {
    const latitude = geoRez.coords.latitude
    const longitude = geoRez.coords.longitude
    setPosition({latitude, longitude});
  };
  const onError = (error) => {
    setError(error.message);
  };
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Browser not support Geolocation API...');
      return;
    }
    const watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  const wait = (position.latitude === undefined) &&(error === null)
  return {...position,wait, error};
}
