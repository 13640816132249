import React from "react"
import { Button,Collapse,Card,CardBody } from "reactstrap"

import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { usePageData1 } from "../../comcom/PageData1"
import { toInteger,dateTimeStrToStr3 } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import { Line1 } from "../../comcom/Line1"
import { LineIn1 } from "../../comcom/LineIn1"
import Table1 from "../../comcom/Table1";
import Form1 from "../../comcom/Form1"
import Comment1 from "../Com/Comment1"
import Emo from "../../comcom/Emo"
import Medicard from "./Medicard"
import Compliman from "./Compliman"


import PromoCli from "../PromoMat/PromoCli"

const DoctorInfo1 = (props) =>{
    const IDORGSOTR = toInteger(props.IDORGSOTR)
    let RefreshButton = props.RefreshButton?props.RefreshButton : true
    const [isOpenVV, setIsOpenVV] = React.useState(false) 
    const [isOpenKK, setIsOpenKK] = React.useState(false) 
    const [isOpenSMS, setIsOpenSMS] = React.useState(false)     
    const [isOpenKOL_P, setIsOpenKOL_P] = React.useState(false)  
   
    const loadData = async ()=>{
        if(IDORGSOTR){
            return await Eng001.getDoctor2({IDORGSOTR:IDORGSOTR})
        }else{
            return false
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)


    if(!IDORGSOTR){
        return <>    
        Error parameter....
    </>

    }
    if(loading || !data) return  <Wait1 />
    if(err) return <Error1 />
    
    if(!Array.isArray(data) || data.length===0){
        return <>    
        Error parameter....
    </>
    }
    const toggleVV = () => {setIsOpenVV(!isOpenVV)}
    const vizits = data[0]['VIZITS']
    const vizitsH=['Дата','МП','ПРАЦЮЄ?' ]
    const vizitsF=['DTVIZIT','NAME1','IFWORK' ]    

    const toggleKK = () => {setIsOpenKK(!isOpenKK)}
    let KK = data[0]['KK']
    if (!Array.isArray(KK) || KK.length ==0 ) KK = false
    const KK_H = ['Рік','Місяць','Бали' ]
    const KK_F = ['YY','MM','KOL_BAL']    

    let KKONF = data[0]['KKONF']
    if (!Array.isArray(KKONF) || KKONF.length ==0 ) KKONF = false
    const KKONF_H = ['Конференція','Статус','##' ]
    const KKONF_F = ['CONFNAME','DOCSTATUS','IDDOC']    


    const toggleSMS = () => {setIsOpenSMS(!isOpenSMS)}
    let SMS = false //data[0]['SMS'] компліман зробив окремим блоком
    if (!Array.isArray(SMS) || SMS.length ==0 ) SMS = false
    const SMS_H = ['Тип','СМС','Видано' ]
    const SMS_F = ['TXT1','DTMSG','DTVIDACHI']    

    const vidatKoml = async (p) => { 
        let vidano = false
        let r = true
        let j = -1
        for(let i=0;i<SMS.length;i++){
            if(SMS[i]['ID'] == p){
                j = i
                if(SMS[i]['DTVIDACHI'] != '') vidano = true
            }
        }
        if(vidano){
            r = window.confirm('Зняти "видано"?')
        }
        if(r){
            let param = {IDORGSOTR: IDORGSOTR, ID : p}
            r = await Eng001.vidatKoml(param)
            if( r> 0){
                if(vidano) SMS[j]['DTVIDACHI'] = ''
                else SMS[j]['DTVIDACHI'] = 'Видано'

            }
        }
    }
    const IDORG=data[0]['IDORG']

    const saveKOL_P1234 = async (p) =>{
        let param = {IDORGSOTR:IDORGSOTR,
            KOL_P1 : p.formData.KOL_P1,
            KOL_P2 : p.formData.KOL_P2,
            KOL_P3 : p.formData.KOL_P3,
            KOL_P4 : p.formData.KOL_P4,
            KOL_P5 : p.formData.KOL_P5,
            KOL_P6 : p.formData.KOL_P6,
            KOL_P7 : p.formData.KOL_P7,
            KOL_P8 : p.formData.KOL_P8,
            L1 : p.formData.L1,
            L2 : p.formData.L2,
            L3 : p.formData.L3,
            L4 : p.formData.L4,
            L5 : p.formData.L5,
            L6 : p.formData.L6,
            L7 : p.formData.L7,
            L8 : p.formData.L8,
            L9 : p.formData.L9,
            L10 : p.formData.L10,
            L11 : p.formData.L11,


        }
        await Eng001.saveVV1234(param)
    }
    let KOL_P1=0,KOL_P2=0,KOL_P3=0,KOL_P4=0,KOL_P5=0,KOL_P6=0,KOL_P7=0,KOL_P8=0
    let L1=0,L2=0,L3=0,L4=0,L5=0,L6=0,L7=0,L8=0,L9=0,L10=0,L11=0
    if(data[0]){
        L1 = data[0]['L1']
        L2 = data[0]['L3']
        L3 = data[0]['L3']
        L4 = data[0]['L4']
        L5 = data[0]['L5']
        L6 = data[0]['L6']
        L7 = data[0]['L7']
        L8 = data[0]['L8']
        L9 = data[0]['L9']
        L10 = data[0]['L10']
        L11 = data[0]['L11']


        KOL_P1 = data[0]['KOL_P1']
        KOL_P2 = data[0]['KOL_P2']
        KOL_P3 = data[0]['KOL_P3']
        KOL_P4 = data[0]['KOL_P4']
        KOL_P5 = data[0]['KOL_P5']
        KOL_P6 = data[0]['KOL_P6']
        KOL_P7 = data[0]['KOL_P7']
        KOL_P8 = data[0]['KOL_P8']

    }
    const fields = [
        {fname:'L0',ftype :'info2',fcaption:'ЛОЯЛЬНІСТЬ:'},
        {fname:'L1',ftype :'number',fcaption:'Мовекс Актив',fnotNull:true,fval:L1},
        {fname:'L2',ftype :'number',fcaption:'Мовекс Комфорт',fnotNull:true,fval:L2},
        {fname:'L3',ftype :'number',fcaption:'Мовіксикам',fnotNull:true,fval:L3},
        {fname:'L4',ftype :'number',fcaption:'Мовіназа',fnotNull:true,fval:L4},
        {fname:'L5',ftype :'number',fcaption:'ПульмоЛОР',fnotNull:true,fval:L5},
        {fname:'L6',ftype :'number',fcaption:'ПульмоБРІЗ',fnotNull:true,fval:L6},
        {fname:'L7',ftype :'number',fcaption:'Остеоцитрат',fnotNull:true,fval:L7},
        {fname:'L8',ftype :'number',fcaption:'Мовіфлекс ДЕКС',fnotNull:true,fval:L8},
        {fname:'L9',ftype :'number',fcaption:'Хелпекс ЛАР',fnotNull:true,fval:L9},
        {fname:'L10',ftype :'number',fcaption:'Мовіксикам амп.',fnotNull:true,fval:L10},
        {fname:'L11',ftype :'number',fcaption:'Мовіксикам ОДТ',fnotNull:true,fval:L11},

        {fname:'L0',ftype :'info2',fcaption:'Кількість пацієнтів на місяць:'},
        {fname:'KOL_P1',ftype :'text',fcaption:'Хондропротектори',fnotNull:true,fval:KOL_P1},
        //{fname:'KOL_P1',ftype :'select',fcaption:'Хондропротектори',fnotNull:true,fval:KOL_P1,fvals:['A','B','C','']},
        {fname:'KOL_P2',ftype :'text',fcaption:'Серратиопептидаза',fnotNull:true,fval:KOL_P2},
        {fname:'KOL_P3',ftype :'text',fcaption:'НПВС таблетовані',fnotNull:true,fval:KOL_P3},
        {fname:'KOL_P4',ftype :'text',fcaption:'НПВС ін"єкційні',fnotNull:true,fval:KOL_P4},
        {fname:'KOL_P5',ftype :'text',fcaption:'Засоби від кашлю',fnotNull:true,fval:KOL_P5},
        {fname:'KOL_P6',ftype :'text',fcaption:'Препарати кальцію',fnotNull:true,fval:KOL_P6},
        {fname:'KOL_P7',ftype :'text',fcaption:'Біль у горлі',fnotNull:true,fval:KOL_P7},
        {fname:'KOL_P8',ftype :'text',fcaption:'Знеболюючі',fnotNull:true,fval:KOL_P8},

    ] 

    const formCFG = {
        //caption : "Кількість пацієнтів на місяць",
        buttonCaption : "Зберегти",
        //cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : saveKOL_P1234,
        grid :2,
        //actionCancel : togglePWD,
        //clearData : true,
        //localStorageTag : 'form1',
    } 
    const toggleKOL_P = () => {setIsOpenKOL_P(!isOpenKOL_P)}




    //<Button  outline style={{float: 'right'}} >{Emo.Edit1()}</Button>
    RefreshButton = false
     return <>
        {RefreshButton && <Button color="primary" outline onClick={fLoadData}> Оновити</Button>}
        <Line1 p={'Лікар:'} v={data[0]['NAME1']}   />  
        
        <Line1 p={'Спеціальність:'} v={data[0]['VRACHSPEC']}   />  
        <Line1 p={'Працює:'} v={data[0]['IFWORK']}   />  
            <LineIn1 p={'АВС'} v={data[0]['ABC']}  />
            <LineIn1 p={'Лояльн'} v={data[0]['LL']}  />
        <Line1 p={'Тел:'} v={data[0]['TEL_PERS']}   />  
        <Line1 p={'день народж:'} v={data[0]['BIRTHDAY']}   />  


        <Line1 p={'ЛПЗ:'} v={data[0]['ORGNAME']} />
        <Line1 v={data[0]['ADR']} />
        <Line1 p={'Регіон'} v={data[0]['DISTR1']}  />
            <LineIn1 p={'Обл'} v={data[0]['OBL']}  />
            <LineIn1  v={data[0]['OBLPART']}  />
            
        <br /><Button  outline onClick={toggleKOL_P} > Лояльність та Кільк пацієнтів на місяць </Button>
                    <Collapse isOpen={isOpenKOL_P}>
                        <Card>
                            <CardBody>
                                <Form1 formCFG={formCFG}  />
                            </CardBody>
                        </Card>
                    </Collapse>     

        <Line1 v={data[0]['ZAKR_MP']} p={'МП'}  /> 
            <LineIn1 p={'Працює:'} v={data[0]['SOTR_IF_WORK']}  />
        
        {data[0]['LAST_VIZIT'] && <>
                <br /><Button  outline onClick={toggleVV} >Візити (ост:{dateTimeStrToStr3(data[0]['LAST_VIZIT'])}) </Button>
                    <Collapse isOpen={isOpenVV}>
                        <Card>
                            <CardBody>
                                <Table1 headers = {vizitsH} initData= {vizits} fields = {vizitsF} />
                            </CardBody>
                        </Card>
                    </Collapse>     
        </>}       
        {KK && <>
                <br /><Button  outline onClick={toggleKK} >KK </Button>
                    <Collapse isOpen={isOpenKK}>
                        <Card>
                            <CardBody>
                                {KKONF && <>
                                    Конференції:
                                    <Table1 headers = {KKONF_H} initData= {KKONF} fields = {KKONF_F} />
                                </>}                                
                                <Table1 headers = {KK_H} initData= {KK} fields = {KK_F} />

                            </CardBody>
                        </Card>
                    </Collapse>     
        </>}                  
        {SMS && <>
                <br /><Button  outline onClick={toggleSMS} >Комплімент </Button>
                    <Collapse isOpen={isOpenSMS}>
                        <Card>
                            <CardBody>
                                <Table1 headers = {SMS_H} initData= {SMS} fields = {SMS_F} 
                                        tableLineButton = {Emo.Edit1()}
                                        tableLineButtonFun = {vidatKoml}
                                        tableLineButtonFunKeyFld = {'ID'}

                                
                                />
                            </CardBody>
                        </Card>
                    </Collapse>     
        </>}              
        <br />
        <PromoCli IDORGSOTR={IDORGSOTR} IDORG={IDORG} />
        <br />
        <Compliman IDORGSOTR={IDORGSOTR} />
        <br />
        <Medicard IDORGSOTR={IDORGSOTR} />
        <br />
        <Comment1 TXT={data[0]['COMMENT']} TABLE={'VV'} ID={IDORGSOTR} />
        <hr />

    </>
}
//<Table1 headers = {sotrH} initData= {dsotr} fields = {sotrF} />
//<Line1 p={'Ост.візит:'} v={dateTimeStrToStr3(data[0]['LAST_VIZIT'])}   /> 
export default DoctorInfo1