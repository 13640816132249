import {NowToStr} from "./comcom/ComFunc"


export const localStorageName = 'wt5Movihealth'
export const VERSION = 'v10.3';

export const ComConst = {
   //SERVERPATH : "http://localhost:8081"
   //SERVERPATH : "http://192.168.2.210:8081"
    //SERVERPATH : "https://192.168.2.210:8081"
    //SERVERPATH : 'http://217.160.171.4:8081'
    //SERVERPATH : 'https://217.160.171.4:8081'
    SERVERPATH : 'https://crm3.movi-health.com.ua:8081'
    
}

export const RoutPath = {
    Main : '/main',
    Work1 : '/mnu/100',
    Pharmacy1 : '/mnu/2',
    Doctor1 : '/mnu/16',
    DoctorVizit1 : '/DoctorVizit1',
    PharmVizit : '/PharmVizit',
    Work1RM : '/Work1RM',
    VizitsMP : '/mnu/5',
    NetContr : '/mnu/19',
    Work2 : '/mnu/501',
    Instructions : '/mnu/502',
    HidProd : '/mnu/8',
    Tabel : '/mnu/11',
    WTDOC : '/WTDOC',
    UzgodgList : '/mnu/36',
    PageSpivr : '/mnu/63',
    PageNewsADM : '/mnu/503',
    PageKrStil :'/PageKrStil'
    
}

export const CachingTag = {
    localStorageName : 'wt5Movihealth',
    DayWorkCash : 'WT_WORK1_DATA',
    PharmacyCash : 'WT_PHARMACY',
    PharmacyFormFilter : 'WT_FormPh1',
    DoctorsCash : 'WT_DOCTORS',
    DoctorsFormFilter : 'WT_FormDoc1',
    VizitsMP : 'WT_VizitsMP',
    VizitsMPFORM : 'WT_VizitsMPFORM',
    NetContr : 'WT_NetContr',
    Work2 : 'WT_Work2MP',
    Instructions : 'WT_Instructions',
    HidProd : 'WT_HidProd',
    Tabel_1 : 'WT_Tabel_1',
    Tabel_2 : 'WT_Tabel_2',
    Uzgodg1 : 'WT_Uzgodg1',
    Uzgodg1_F : 'WT_Uzgodg1_F',
    PageUzgodgList : 'WT_PageUzgodgList',
    PageSpivr : 'WT_PageSpivr',
    PharmVizitTMP : 'WT_PharmVizitTMP'

}

export const removeAllCashData = () =>{
    localStorage.removeItem(localStorageName)
    for(let key in CachingTag)
        localStorage.removeItem(CachingTag[key])
}

export const remoteDayWorkCash = () =>{
    localStorage.removeItem(CachingTag.DayWorkCash)
   
}

export const setOkInDayWorkCash = (IDJOBTP,IDWORK,DTFIX) =>{
    if(!DTFIX) DTFIX = NowToStr(new Date())
    let ifChange = false
    //console.log('=====================================================')
    //console.log(IDJOBTP,IDWORK,DTFIX)
    let wd = localStorage.getItem(CachingTag.DayWorkCash)
    if(wd) {
        wd = JSON.parse(wd)
       
        //console.log(wd)
       
        for(let i=0; i<wd.data.works.length;i++){
            if(wd.data.works[i].IDJOBTP==IDJOBTP && wd.data.works[i].IDWORK==IDWORK && wd.data.works[i].IDJOB==0 ){
                //console.log('FIND!!!!')
                wd.data.works[i].DTFIX = DTFIX
                wd.data.works[i].DATEVIZIT = DTFIX 
                wd.data.works[i].IDJOB = 2
                ifChange = true
            }
        }
        //if(ifChange)   localStorage.setItem(CachingTag.DayWorkCash,JSON.stringify(wd))
        //console.log(wd)
    }
}
