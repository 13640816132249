import React from "react";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom"

import Layout from "../components/Layout";

import PageMainForm from "./PageMainForm";
import PageLoginForm from "./PageLoginForm";
import PageUserForm from "./PageUserForm"
import PageWork1 from "./PageWork1";
import PagePharmacy1 from "./PagePharmacy";
import PageDoctor1 from "./PageDoctor";
import PageNetContr from "./PageNetContr";
import PageTestCoordinat from "./PageTestCoordinat";
import PageDoctorVizit from './PageDoctorVizit'
import PagePharmVizit from "./PagePharmVizit";
import PageWork1RM from "./PageWork1RM"
import PageVizitsMP from "./PageVizitsMP";
import PageWork2 from "./PageWork2";
import PageInstructions from "./PageInstructions";
import PageHidProd from "./PageHidProd";
import PageTabel from "./PageTabel";
import PageWTDoc from "./PageWTDoc";
import PageWtDocList from "./PageWTDocList";
import PageSpivr from "./PageSpivr"
import PageNewsADM from "./PageNewsADM";
import PageKrStil from "./PageKrStil";
import { RoutPath } from "../WTConst";


// <Route exact path="/" element={<MainForm />} />
//<Route path="/*" element={<MainForm />} />

const Pages = props =>{
    return(
        <BrowserRouter>
        <Layout>
            <Routes>
                
                <Route path={RoutPath.Main} element={<PageMainForm />} />
                <Route path="/login" element={<PageLoginForm />} />
                <Route path="/user" element={<PageUserForm />} />
                <Route path={RoutPath.Work1} element={<PageWork1 />} />
                <Route path={RoutPath.Pharmacy1} element={<PagePharmacy1 />} />
                <Route path={RoutPath.Doctor1} element={<PageDoctor1 />} />
                <Route path={RoutPath.DoctorVizit1} element={<PageDoctorVizit />} />
                <Route path={RoutPath.PharmVizit} element={<PagePharmVizit />} />
                <Route path={RoutPath.Work1RM} element={<PageWork1RM />} />
                <Route path={RoutPath.VizitsMP} element={<PageVizitsMP />} />
                <Route path={RoutPath.NetContr} element={<PageNetContr />} />
                <Route path={RoutPath.Work2} element={<PageWork2 />} />
                <Route path={RoutPath.Instructions} element={<PageInstructions />} />
                <Route path={RoutPath.HidProd} element={<PageHidProd />} />
                <Route path={RoutPath.Tabel} element={<PageTabel />} />
                <Route path={RoutPath.WTDOC} element={<PageWTDoc />} />
                <Route path={RoutPath.UzgodgList} element={<PageWtDocList />} />
                <Route path={RoutPath.PageSpivr} element={<PageSpivr />} />
                <Route path={RoutPath.PageNewsADM} element={<PageNewsADM />} />
                <Route path={RoutPath.PageKrStil} element={<PageKrStil />}  />
                <Route path="*" element={<PageMainForm />} />
                
                
                <Route path="/TestCoordinat" element={<PageTestCoordinat />} />
            </Routes>
        </Layout>    
        </BrowserRouter>
    )
}
export default Pages
