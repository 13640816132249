import React from "react";

const Error1 = (props) =>{
    const msg = props.msg?props.msg:"Помилка..."
    return (  <div>
             {msg && (<h6>{msg}</h6>)}
            </div>)
}



export default Error1