import React from "react";
import { Button,Collapse,Card,CardBody,CardHeader,CardText } from "reactstrap"


import Emo from "../../comcom/Emo";
import Eng001 from "../../engine/eng001";
import Wait1 from "../../comcom/Wait1";
import Error1 from "../../comcom/Error1";
import Table1 from "../../comcom/Table1";



const Avto1Zapr = ({IDAVTO}) =>{

    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(true)

    const loadData = async ()=>{
        setloading(true)
        setData( false )
        setErr( false)        
        let d = await Eng001.loadAvtoZapr({IDAVTO})
        if(d){
            setloading(false)
            setData( d )
        } else {
            setloading(false)
            setErr( true)        
        }
    }
    const toggle = () => setIsOpen(!isOpen);

    const pH=['Дата','Літри','Сума','Паливо']
    const pF=['DT','LITR','SUMMA','FUEL']   

    return <>
        {loading && <Wait1 />}
        {err && <Error1 />}
        {!data && !loading && <Button  outline on onClick={loadData}>Останні заправки</Button>}
        {data && data.length>0 && <div>
                    <br />
                    <Button  outline onClick={toggle}>Останні заправки</Button>
                    <Collapse isOpen={isOpen}>
                        <Card>
                            <CardBody>
                            <Table1 headers = {pH} initData= {data} fields = {pF}  />  
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
        }
    </>
}

export default Avto1Zapr