//короткі відомості про узгодження

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card,CardBody,CardHeader,CardText} from "reactstrap";
import {ComConst} from "../../WTConst"

const NewsPrev = ({d}) =>{
    const navigate = useNavigate()
    let hStyle = {fontWeight:'bold'}
    //if(d.ALARM!='') hStyle = {fontWeight:'bold',color:'red'}

    //let src = `data:image/png;base64,${d.IMGFILE}`
    if(!d) return <></>
    let src = false;
    if(d.FILENAME_IN_DB) src = ComConst.SERVERPATH  +d.FILENAME_IN_DB //"http://192.168.2.210:8081"
    let imgWidth = "300"
    if(window.screen.availWidth > 1000)    imgWidth = "760"  
    imgWidth = "100% "
    return <>
        <Card style={{ borderRadius: "1em"}}>
            <CardHeader>
                   <span style={hStyle}>{d.DOCTP}</span>
            </CardHeader>
            <CardBody>
            <CardText>
                 <b>{d.TXT1}</b>
                 {src && <>
                    <br />
                    <img src={src}  width={imgWidth} />
                    </>
                    }
                  
            </CardText>
            </CardBody>                    
        </Card>
        <br />
    
    
    </>
}
//width="300"

export default NewsPrev