import React from "react";
import { Collapse, Button, CardBody, Card} from 'reactstrap';

import Emo from "./Emo";

import Form1 from "./Form1";
import Error1 from "./Error1";
import Wait1 from "./Wait1";
import Table1 from "./Table1";

const Table1AndFilter = props => {
/*
    Параметри
        formHeader,          - заголовок форми
        loadDataFromServer,  - функція для запиту даних, асинзронна
        fieldsFilterForm,    - поля форми параметрів запиту даних з сервера (див нижче)
        filterOpt,           - чи показувати строку Пошук/Фыльтр
        Theaders,            - заголовки столюців табличної частини (див нижче)
        Tfields,             - поля в датасеті data
        data,                - дані в вигляді масиву [{f1:'fff',f2:...}....]
        loading,             - трує - малюємо Loading ...
        err,                 - трує - малюємо Error ...
        fieldsForAction      - перелік полів які реагують на клік, будуть підкреслені
        actionInTable,       - процедура при кліку на fieldsForAction (див нижче)
        currRowInTable,      - на яку строку переходить при поверненні на сторінку
        setCurrRowInTable    - процедура для зміни currRowInTable
        
        tableLineButton      - напис на кнопці в строках таблиці
        tableLineButtonFun   - процедура кнопки
        tableLineButtonFunKeyFld - 

    Приклади даних
    const fieldsFilterForm = [
        {fname:'ORGNAME',ftype :'text',fcaption:'Назва',fhint:'Назва',fnotNull:false,fval:''},
        {fname:'ADR',ftype :'text',fcaption:'Адреса',fhint:'Адреса',fnotNull:false,fval:''},
        {fname:'MP',ftype :'text',fcaption:'Закріплення',fhint:'Закріплення',fnotNull:false,fval:''},
        ]
    const Theaders = Props.Theaders.['Назва','Адреса','Закріплення','Тип','ABC','Ост.візит']
    const Tfields = ['ORGNAME','ADR','NAME1','TPZAKR','ABC','DTLASTVIZIT']
    
    const ActionInTable = (row,fn,rowNN) => {


            
*/



    const [isFilterShow, setFilterShow] = React.useState(true);

    let {formHeader,
           loadDataFromServer,
           fieldsFilterForm,
           Theaders,
           Tfields,
           filterOpt,
           data,
           loading,
           err,
           fieldsForAction,
           actionInTable,
           currRowInTable,
           setCurrRowInTable,
           localStorageTag,
           tableLineButton,
           tableLineButtonFun,
           tableLineButtonFunKeyFld,
        } = props

    if(!setCurrRowInTable) setCurrRowInTable = () =>{}

    const loadData = async (ff) =>{
        let d = await loadDataFromServer(ff.formData)
        setFilterShow(false) //сховали форму фільтра
        setCurrRowInTable(0)
        return d
    }

    const filterShow = ()=> {
        setCurrRowInTable(0)
        setFilterShow(!isFilterShow)
    }
  

    const formFilter = {
        caption : "",
        buttonCaption : "Вибрати дані",
        cancelBtnCaption : 'Сховати',
        formMsg : '',   
        formErrorMsg : '',
        fields : fieldsFilterForm,
        action : loadData, //refreshData,
        actionCancel : filterShow,
        clearData : false,
        grid:1,
        localStorageTag:localStorageTag,
    } 

    
    //if(err) return <Error1 />
    //if(loading) return <Wait1 />
// {err && <p>Повторіть виборку даних...</p>}
    
    return (
        <>
         <Button color="link"  outline onClick={filterShow}><h4>{Emo.Look1()}{formHeader}</h4></Button>  
            <Collapse isOpen={isFilterShow}>
            <Card>
                <CardBody>
                    <Form1 formCFG={formFilter}  />
                        
                </CardBody>
            </Card>
            </Collapse>
           
            {loading && <Wait1 />}
            {data && 
            <p>
                <Table1 headers = {Theaders} 
                        fields = {Tfields} 
                        initData = {data} 
                        fieldsForAction={fieldsForAction}
                        action={actionInTable} 
                        filterOpt={filterOpt} 
                        currRowInTable={currRowInTable}
                        tableLineButton={tableLineButton}
                        tableLineButtonFun={tableLineButtonFun}
                        tableLineButtonFunKeyFld={tableLineButtonFunKeyFld}
                        />
            </p>}
            
        </>        
    )
}

export default Table1AndFilter