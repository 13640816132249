import React from "react";
import { Button } from "reactstrap";
import ShowGMap1 from '../comcom/GMap1'
import Emo from "../comcom/Emo";
import {getDistanceFromLatLonInM} from "../comcom/ComFunc";

const ShowGeoPosition2 = ({lat1,long1,capt1='1',lat2=0,long2=0,capt2='2',showDist=0, largeDist=500})=>{
    const [ifMap,setMap] = React.useState(false)
    const btnMapClick = ()=> setMap(!ifMap)
    const points = [
        {lat: lat1, lng: long1, title : capt1,label : capt1},
    ]
    if(lat2) points.push({lat: lat2, lng: long2, title : capt2,label : capt2})
    let vidh = false
    let largeVidh = false
    if(showDist && lat1>0 && long1>0 && lat2>0 && long2>0) vidh = getDistanceFromLatLonInM(lat1,long1,lat2,long2)
    if(vidh && vidh > largeDist) {largeVidh = vidh; vidh = false}
    
    return <>
        {lat1 && <div style={{color: 'blue'}}>
            {largeVidh && <span style={{color: 'red'}}>Відхилення:{largeVidh}м!!! </span> }
            {vidh && <>Відхилення:{vidh}м</>}
            {'  '}
            Показати на мапі - <Button  color="info"  size="sm" outline onClick={btnMapClick}> {Emo.Look1()}  </Button>{' '}
        </div>}
        {ifMap&& <ShowGMap1 points={points}/> }
    
    </>
}

export default ShowGeoPosition2