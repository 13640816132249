import React from "react";
import { Button } from "reactstrap";
import ShowGMap1 from '../comcom/GMap1'
import Emo from "../comcom/Emo";

const ShowGeoPosition = ({latitude,longitude,wait,error,capt='Ви тут'})=>{
    const [ifMap,setMap] = React.useState(false)
    const btnMapClick = ()=> setMap(!ifMap)
    const points = [
        {lat: latitude, lng: longitude, title : 'Ви знаходитесь тут',label : 'Ви тут'},
    ]
    return <>
        {wait && <div>Визначення координати, хвилинку ...</div>}
        {error && <div style={{color: 'red'}}>Координати ПОМИЛКА:{error}</div>}
        {latitude && <div style={{color: 'blue'}}>
            <Button  color="info"  size="sm" outline onClick={btnMapClick}> {Emo.Look1()}  </Button>{' '}
            {capt}(широта,довгота): {latitude}, {longitude}
        </div>}
        {ifMap&& <ShowGMap1 points={points}/> }
    
    </>
}

export default ShowGeoPosition