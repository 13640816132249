import React from "react";
import { Button,Collapse, CardBody, Card } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import Table1 from "../../comcom/Table1";
import { toInteger } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"


const ZvitTable = (props) =>{
    const [isOpen, setIsOpen] = React.useState(false) 
    const data = props.data
    if(!data){return <></>}
    const ddata = data['REP']   
    if(ddata.length===0){return <></>}
    let sumIn=0,sumOut=0,sumO=0
    for(let i=0;i<ddata.length;i++){
        sumIn = sumIn + ddata[i]['KOL_IN'] * ddata[i]['PRICE']
        sumOut = sumOut + ddata[i]['KOL_OUT'] * ddata[i]['PRICE']
        sumO = sumO + ddata[i]['KOL_O2'] * ddata[i]['PRICE']
    }
    const h = `Закупка:${Math.round(sumIn)}грн  Продажі:${Math.round(sumOut)}грн Вих.зал:${Math.round(sumO)}грн`
    const repNetH = ['Назва','Закуп', 'Прод', 'Вих.зал']
    const repNetF = ['TABL','KOL_IN', 'KOL_OUT', 'KOL_O2']
    const toggle = () => setIsOpen(!isOpen);

    return <>
            <br />
            <Button  outline onClick={toggle} >Звіт за {data['MM']}/{data['YY']}{' '}{data['TP']}</Button>
                    <Collapse isOpen={isOpen}>
                        <Card>
                            <CardBody>
                                <b>{h}</b>
                                <Table1 headers = {repNetH} initData= {ddata} fields = {repNetF} />  
                            </CardBody>
                        </Card>
                    </Collapse>

    </>
}


export const ZvitMeregi = (props) => {
    const IDORG = toInteger(props.IDORG)
    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
       
    const loadData = async ()=>{
        setloading(true)
        setData( false )
        setErr( false)        
        let d = await Eng001.loadZvitMeregi({IDORG:IDORG})//loadNetRep1
        if(d){
            setloading(false)
            setData( d )
        } else {
            setloading(false)
            setErr( true)        
        }
    }

    const d1 = data[0]
    const d2 = data[1]
    return <>
        {loading && <Wait1 />}
        {err && <Error1 />}
        {!data && !loading && <Button  outline on onClick={loadData}>Звіти мережі...</Button>}
        {d1 && <ZvitTable data={d1} /> }
        {d2 && <ZvitTable data={d2} /> }
        
    </>
}