import React from "react"
import { Button,Collapse,Card,CardBody, CardHeader,
    Modal, ModalHeader, ModalBody } from "reactstrap"

import Emo from "../../comcom/Emo"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger,dateTimeStrToStr3 } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import Table1 from "../../comcom/Table1";
import { usePageData1 } from "../../comcom/PageData1"

const ComplimanTable = ({IDORGSOTR}) =>{
    const loadData = async ()=>{
        let d = await Eng001.loadCompliment({IDORGSOTR})
        return d
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)
    const pH=['Номер','Додано','Підтв','Повідомл','Viber','Видано','Приз']
    const pF=['TXT1','PPMP','PPRM','DTMSG','VIBER','DTVIDACHI','T1' ]    

    const btnClick = async (ID)=>{
        //alert("btnClick "+ID)
        let currRet = {ID :0}
        for(let i=0;i<data.length;i++){
            if(data[i]['ID'] == ID) currRet = data[i]
        }
        let act = '' 
        if(currRet['ID']>0){
            if(!currRet['DTMSG']){
                if(window.confirm('Ставимо "передано вайбером"?'))
                    act = 'VIBER'
            }else{
                if(!currRet['DTVIDACHI']){
                    if(window.confirm('Видаємо приз?'))
                        act = 'VIDAV'
                }
            }
            if(act){
                await Eng001.updateCompliment({ACT:act,ID,IDORGSOTR})
                fLoadData()
            }else{
                alert('запис закрито...')
            }
        }

    }


    if (loading) return <Wait1 />
    if (err) return <Error1 />

    return <>

        <CardBody>
            <Table1 headers = {pH} initData= {data} fields = {pF} 
                tableLineButton ={Emo.Ok2()} 
                tableLineButtonFun={btnClick}
                tableLineButtonFunKeyFld={'ID'}            
            />    
        </CardBody>

    </>
}

//tableLineButton ={Emo.Cancel1()} 
//tableLineButtonFun={deleteVidachu}
//tableLineButtonFunKeyFld={'IDOST'}




const Compliman = (props) =>{
    const IDORGSOTR = toInteger(props.IDORGSOTR?props.IDORGSOTR : 0)
    const [isOpen, setIsOpen] = React.useState(false)


    const toggle = () => {
        setIsOpen(!isOpen)
    }
    


    return <>
        <Button  outline onClick={toggle} >Комплімент</Button>
        <Collapse isOpen={isOpen}>
            <Card>
                 <ComplimanTable IDORGSOTR={IDORGSOTR} />
            </Card>
        </Collapse>
    </>
}
export default Compliman
