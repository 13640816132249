import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, CardTitle, CardText, Button, Input 
    ,Modal, ModalHeader, ModalBody} from "reactstrap"


import Eng001 from "../../engine/eng001";
import { usePageData1 } from "../../comcom/PageData1";
import Wait1 from "../../comcom/Wait1";
import Error1 from "../../comcom/Error1";
import { usePosition } from "../../comcom/geo1"
import ShowGeoPosition from "../ShowGeoPosition";
import { cloneObj } from "../../comcom/ComFunc"
import Input1 from "../../comcom/Input1"
import EmplEdit1 from "../Empl/EmplEdit1"
import Styles1 from "../../comcom/Styles1";
import { RoutPath,setOkInDayWorkCash,remoteDayWorkCash,CachingTag } from "../../WTConst";
import usePageDataCashed1 from "../PageDataCashedWT1";
import Op from "../Com/Op";

const PharmVizitD = (props) =>{
    const [addEmpl, setAddEmpl] = React.useState(false);
    
    const IDVIZITD = props.IDVIZITD
    const IDORG = props.IDORG
    let IDVIZITTP = 1 //1 - норм візит 2 - дист
    let CAN_DIST_VIZIT = 0
    let CAN_VIZIT_NO_COORD = 0
    const { latitude, longitude, wait, error } = usePosition()
    const navigate = useNavigate()

    const loadData = async () => {
        const d = await Eng001.loadPharmVizit({IDVIZITD:IDVIZITD})
        let Op = await Eng001.loadOp({'IDOPTP':2,IDVIZITD,IDORG})
        d['OP'] = Op
        d.IDVIZITD = IDVIZITD
        return d
    }
    const testData = (cashedData) => {
        if(!cashedData) return false
        if(!cashedData.data) return false
        return cashedData.data.IDVIZITD == IDVIZITD
    }

   // const [data,loading,err,fLoadData,setNewData,setloading] =  usePageData1(loadData)
    const [data,loading,err,fLoadData,setNewData,setloading] =usePageDataCashed1(CachingTag.PharmVizitTMP,loadData,testData)
    //data,loading,err,fReloadData,setNewData
    const saveData = async (param) => {
        if(!latitude && !CAN_VIZIT_NO_COORD) {
            alert('Не визначено координати ...')
            return
        }
        if( !latitude ) {
            if (!window.confirm('Не визначено координати, ПРОДОВЖУЄМО?'))
                return
        }

        param = {}
        param.latitude = latitude
        param.longitude = longitude
        param.IDVIZITD = IDVIZITD
        param.IDORG = IDORG
        if(!param.IDVIZITTP) param.IDVIZITTP = 1
        else param.IDVIZITTP = param.IDVIZITTP + 1 
        param.EMPL = []
        data.EMPL.map((m,i) => {param.EMPL.push({IDORGSOTR:m.IDORGSOTR,IFV:m.IFV})})
        param.PREP = []
        data.PREP.map((m,i) => {param.PREP.push({ID:m.ID,O:m.O,Z:m.Z})})
        param.IFDVV = data.DVV['IFDVV']
        param.DVV = data.DVV['DVV']
        try{
            setloading(true)
            let r = await Eng001.savePharmVizit(param)
        }finally{
            setloading(false)
        }
        localStorage.removeItem(CachingTag.PharmVizitTMP)
        remoteDayWorkCash()
        navigate(RoutPath.Work1)
        alert('Ok')
    }
 
    if(loading || !data) return  <Wait1 />
    if(err) return <Error1 />
    
    if(!Array.isArray(data.PREP) || data.PREP.length===0){  return <>Error parameter.... </>}

    let plan = 0
    let zakaz = 0
    let ost = 0
    let PROD1 = 0
    const calcItogo = () =>{
        plan = 0
        zakaz = 0
        ost = 0
        PROD1 = data.PREP[0].PROD1
        for(let i=0;i<data.PREP.length;i++){
            plan += data.PREP[i].in_plan * data.PREP[i].PRICE
            zakaz += data.PREP[i].Z * data.PREP[i].PRICE
            ost += data.PREP[i].O * data.PREP[i].PRICE
        }
    }

    const onChange = event =>{
        let f = event.target.name
        let v = event.target.value
        let tmp = cloneObj(data)
        if(f == 'IFDVV' || f == 'DVV'){
            tmp.DVV[f] = v
            setNewData(tmp)
        }else{
            let ff = f.substr(0,1)
            let id = Number(f.substr(2))
            tmp.PREP[id][ff] = v
            setNewData(tmp)
        }
    }    
    const setVal = (F,id) => {
        return ()=>{
            let tmp = cloneObj(data)
            tmp.PREP[id][F] = tmp.PREP[id][F] + 1
            setNewData(tmp)            
        }
    }
    const onChange2 = event =>{
        let f = event.target.name
        let v = event.target.value
        let ff = 'IFV'
        let id = Number(f.substr(4))
        let tmp = cloneObj(data)
        tmp.EMPL[id][ff] = v
        setNewData(tmp)        
    }
    const closeAddEditWindow = async (d) => {
        toggleAddEmpl()
        fLoadData();
    }


    const toggleAddEmpl = () => setAddEmpl(!addEmpl)
    CAN_DIST_VIZIT = data['CAN_DIST_VIZIT']
    CAN_VIZIT_NO_COORD = data['CAN_VIZIT_NO_COORD']
    calcItogo()
    const styleHeader = Styles1.styleBoldBLUE_U()
    const ifOp = (data['OP'].length>0 )
    return <>
        <Modal isOpen={addEmpl} toggle={toggleAddEmpl}>
            <ModalHeader toggle={toggleAddEmpl}>Додаємо...</ModalHeader>
            <ModalBody>
                <EmplEdit1 IDORG={IDORG} IDORGSOTR={0} FUN={closeAddEditWindow} IFLIKAR={0}/>
            </ModalBody>
        </Modal>

        {ifOp &&  <>
            <h4>Заповніть опитування:</h4>
            {data['OP'].map((m,i) => <Op OP={m} OPOPEN={1}/>)}   
            <hr />
            </>
            }




        <span style={styleHeader}>З ким проводили візит</span>
        <Button color="primary"  outline style={{float:"right"}} onClick={toggleAddEmpl}>Додати</Button>
        {data.EMPL.map((d,i)=>{
            return <div>
                <Input1 fname={'D___'+i} ftype="checkboxLeft" fcaption={'('+d.DOLGN +')'+d.NAME1} fval={d.IFV} fonChange={onChange2} />
                </div>
        })}
        <hr />
        <br /><b>План на поточний місяць НА КЛІЄТНА: {plan} грн</b>
        <hr />
        <table>
            <tr>
                <td>
                    <h4>З Вами проводять подвійний візит?</h4>
                </td>
                <td>
                    <Input name="IFDVV" type="select" onChange={onChange} value={data.DVV['IFDVV']}> <option> </option><option>Y</option><option>N</option></Input>
                </td>
            </tr>
            <tr>
                <td>
                    <h4>Хто проводить?</h4>
                </td>
                <td>
                    <Input name="DVV" type="text" onChange={onChange} value={data.DVV['DVV']}> </Input>
                </td>
            </tr>

        </table>
        <hr />
        


        <span style={styleHeader}>Залишки/Замовлення</span>
        {data.PREP.map((m,i)=>{
            return (
                <Card color="light"  outline >
                <CardBody>
                  <CardTitle >
                    <span style={{fontWeight:'bold', fontSize:20}}>{m.TABL}  ({m.PRICE} грн)</span>{' '}
                    План {m.in_plan}. Візит {m.DTVIZIT_OLD}: залишок {m.old_o} замовл {m.old_z}.
                  </CardTitle>
                  <CardText>
                    
                    <table>
                        <tr>
                            <td>
                                <Button outline onClick={setVal('O',i)}><b>Зал(+1)</b></Button>
                            </td>
                            <td style={{width:"8rem"}}>
                                <Input style={{fontWeight:"bold", fontSize:20}} type="number" name={"O_"+i} onChange={onChange} value={m.O}/>
                            </td>
                            <td>
                                <Button outline onClick={setVal('Z',i)}><b>Замовл(+1)</b></Button>
                            </td>
                            <td style={{width:"8rem"}}>
                                <Input style={{fontWeight:"bold", fontSize:20}} color="primary" type="number" name={"Z_"+i} onChange={onChange} value={m.Z}/>
                            </td>
                        </tr>
                    </table>
                  </CardText>
                </CardBody>
              </Card>            
            )
        })}
        <br /><b>План: {plan} грн</b>
        <br /><b>Закупки (попередньо): {PROD1} грн</b>
        <br /><b>Залишок: {ost} грн</b>
        <br /><b>Замовлення: {zakaz} грн</b>
        <hr />
        <Button color="primary"  size="lg" outline onClick={saveData}>Зберегти</Button>
        <hr />
        <ShowGeoPosition latitude={latitude} longitude ={longitude} wait={wait} error={error} />

    </>

}

export default PharmVizitD