import React from "react";
import {  Button,Table } from "reactstrap";


import { CachingTag } from "../../WTConst";
import usePageDataCashedWT1 from '../PageDataCashedWT1'
import Eng001 from "../../engine/eng001";
import Wait1 from "../../comcom/Wait1";
import Input0 from "../../comcom/Input0";
import { dateTimeStrToStr4,objectLength } from "../../comcom/ComFunc";


const TableDay = props =>{
    let d1 = false
    let d2 = false
    const loadTabelDay = async () =>{
        try{
            let d = await Eng001.loadTabelDay()
        
            return d
        }catch(err){
            return false
        }
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.Tabel_1,loadTabelDay)

    if( loading) return <>
        <Button color="primary" outline onClick={funLoadData}>Оновити</Button>
        <Wait1 />
        </>
    if(!data) return <>
        <Button color="primary" outline onClick={funLoadData}>Оновити</Button>
        <br />no data ...
    </>        

    let newData = {}
    const onChange = (vv)=>{
        let n = vv.target.name
        let v = vv.target.value
        newData[n] = v
    }
    const saveDate = async () =>{

        if(objectLength(newData)>0){
            newData.YY = data['d1'][0].YY
            newData.MM = data['d1'][0].MM
            newData.DD = data['d1'][0].DD
            let r = await Eng001.saveTabelDay(newData)
            if(r) await funLoadData()
        }
    }
    if(data['d1']) d1 = data['d1']
    let vv = []
    if(data['d2']) {
        d2 = data['d2']
        d2.map((m)=>vv.push(m.SHTP))
    }


    return <>
            <Button color="primary" outline onClick={funLoadData}>Оновити</Button>
            {'   '} Дата : {data['d1'][0].DD} / {data['d1'][0].MM} / {data['d1'][0].YY}
            {d1 && <>
                <Table striped>
                <thead>
                    <tr className="table-secondary">
                        <th>Рег.</th>
                        <th>ПІБ</th>
                        <th>Посада</th>
                        <th>Відмітка</th>
                        <th>МП</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                    {d1.map((v,i)=>{
                        let pp = {fname:'FF_'+v.IDSOTR,
                                  ftype :'select',
                                  fval:v.DAYDATA,
                                  fonChange:onChange,
                                  fvals:vv}
                        return (
                            <tr>
                                <td>{v.DISTRICT}</td>
                                <td>{v.NAME1}</td>
                                <td>{v.SOTRTP}</td>
                                <td style={{width:'5rem'}}><Input0 {...pp} key={v.IDSOTR}/></td>
                                <td >{dateTimeStrToStr4(v.DTFIX)}</td>
                                <td>{v.TXT}</td>
                                <td>{v.OBLPART}</td>
                            </tr>
                            )
                    })}
                </Table>
                <Button color="primary" outline onClick={saveDate}>Зберегти</Button>
            </>}
    </>
}

export default TableDay