import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import appData from "../AppData";
import {dateTimeStrToStr} from "../comcom/ComFunc";
import DoctorInfo1 from "../components/Doctor/DoctorInfo1";
import DoctorVizitD1 from "../components/Work1/DoctorVizitD1";

import RedirectToLogin from '../components/RedirectToLogin'
import { RoutPath } from "../WTConst";
import Emo from "../comcom/Emo";
import Styles1 from "../comcom/Styles1";

const PageDoctorVizit = (props) =>{
    const location = useLocation()
    const navigate = useNavigate()
    const p = location.state

    const IDWORK = p.IDWORK
    const IDORGSOTR = p.IDORGSOTR
    const IDORG = p.IDORG
    const DTVIZIT = p.DTVIZIT
    const IDSOTR = p.IDSOTR
    const MP = p.MP
    const VR = p.VR
    const ORG = p.ORG
    const ADR = p.ADR

    const [docInfo,setDocInfo] = React.useState(false)

    const toggleDocInfo = () => setDocInfo(!docInfo)
    const goToWorkPage = (refresh=false) => {
        const params = {refresh : refresh}
        //navigate(RoutPath.Work1,{state:params})
        navigate(RoutPath.Work1)
    }

    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )
    if(!IDORGSOTR || !DTVIZIT || !IDSOTR) return <>no parameters ... {IDORG} {IDORGSOTR} {DTVIZIT} {IDSOTR}</>
    return <>
        <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button>
        {'  '}
        <span style={Styles1.styleBoldBLUE_U()}>Візит до лікаря</span> 
        <br /><span style={Styles1.styleBoldBLUE()}>{dateTimeStrToStr(DTVIZIT)} МП :{MP}</span>
        {!docInfo && <>
                <br /><span style={Styles1.styleBold_U()}>{VR}</span>
                <br /><span style={Styles1.styleBold()}>{ORG} {ADR}</span>
                <br /> <Button  color="secondary" size="sm" outline onClick={toggleDocInfo} style={{float:'right'}}>Докладніше про лікаря...</Button>                        
                <hr />
                </>}
        
        {docInfo && <>
            <Button  color="secondary" size="sm" outline onClick={toggleDocInfo} style={{float:'right'}}>"Згорнути лікаря"...</Button>                        
            <DoctorInfo1 IDORGSOTR={IDORGSOTR} RefreshButton={''}/>
            </>}
            <DoctorVizitD1 IDVV={IDWORK} IDORGSOTR={IDORGSOTR} IDORG={IDORG}/>
    </>
}

export default PageDoctorVizit








