import React from "react";
import { Button,Form,FormGroup,Label,Input,Nav,NavItem } from "reactstrap"
import Emo from "../../comcom/Emo";

import Eng001 from "../../engine/eng001";

const FIELDNAME = "COMMENT1234567890"
const Comment1 = props =>{
    const ID = props.ID
    const TXT = props.TXT
    const TABLE = props.TABLE
    const [newTXT,setNewTXT] = React.useState(TXT);
    
    const onChange = ev =>{
        //event.target.name) setValues({...values,[event.target.name]:event.target.value
        setNewTXT(ev.target.value)
    }
    const saveComment = async ()=>{
        if(newTXT !== TXT){
            const r = await Eng001.saveComment({ID:ID,TABLE:TABLE,ID:ID,COMMENT:newTXT})
        }
    }

    return <>
            <Form>
                <FormGroup> <Label style={{fontWeight:"bold"}} for={FIELDNAME}>Коментар:</Label>
                <Button color="secondary" outline style={{float:'right'}} onClick={saveComment}>{Emo.Save1()}</Button>
                <Input id={FIELDNAME} name={FIELDNAME} type="textarea" defaultValue={TXT} onChange={onChange}/>
                </FormGroup>
            </Form>    
            </>
}

export default Comment1