import React from "react"
import { Button } from "reactstrap"

import PharmacyInfo1 from "./PharmasyInfo1"
import { toInteger } from "../../comcom/ComFunc"


const Pharmacy2 = (props) =>{
    const IDORG = toInteger(props.IDORG)
    const funcBack = props.funcBack
    
    return <>
        <Button color="primary" outline onClick={funcBack}> Назад</Button>
        <PharmacyInfo1 IDORG={IDORG} />
    </>
}

export default Pharmacy2