
import Eng001 from "../../engine/eng001";
import TableSecondaryData from "../../comcom/TableSecondaryData";


export const PharmAdrProd = (props) =>{
    let IDORG = props.IDORG
    const loadData = async ()=>{
        let d = await Eng001.loadAdrProd({IDORG})
        return d
    }


    return <>
    <TableSecondaryData caption={'Адресні продажі'}
                    loadDataFun={loadData}
                    Param={{IDORG}}
                    Fields={['YY','MM','SHORT_NAME','KOL','NA_SUMM']}
                    ColumnCaption={['Рік','Міс','Препарат','Кільк','На суму']}
                    Comment={'Продажі "дистрів" за адресою за поточний місяць'}
                    />
    </>

}
