import React from "react"
import { Button,Collapse,Card,CardBody, CardHeader,
    Modal, ModalHeader, ModalBody } from "reactstrap"

import Emo from "../../comcom/Emo"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger,dateTimeStrToStr3 } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import Table1 from "../../comcom/Table1";



const PromoCli = (props) =>{
    const IDORGSOTR = toInteger(props.IDORGSOTR?props.IDORGSOTR : 0)
    const IDORG = toInteger(props.IDORG? props.IDORG : 0)
    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(true)

    const [addWindow, setAddWindow] = React.useState(false);
    
    const [promoList,setPromoList] = React.useState(false)

    const loadData = async ()=>{
        setloading(true)
        setData( false )
        setErr( false)        
        let d = await Eng001.loadPromoCli({IDORG:IDORG,IDORGSOTR:IDORGSOTR})
        if(d){
            setloading(false)
            setData( d )
        } else {
            setloading(false)
            setErr( true)        
        }
    }
    const execPromo = async (param) => {
        let KOL = prompt('Кількість', 1)
        if(KOL!=undefined) {
            param.KOL = toInteger(KOL)
            await Eng001.editPromoCli(param)
            loadData()
        }

    }

    const addPromo = async (p) =>{
        setAddWindow(false)
        let param = {IDORG:IDORG,IDORGSOTR:IDORGSOTR,IDPROMOM:p}
        await execPromo(param)

    }
    const editPromo = async (p) =>{
        let param = {IDORG:IDORG,IDORGSOTR:IDORGSOTR,ID:p}
        await execPromo(param)
    }

    
    const pH=['Дата','Промо','Кільк','МП' ]
    const pF=['DT','T1','KOL','NAME1' ]    

    const addH=['Промоматеріал' ]
    const addF=['T1' ]    


    const toggle = () => {setIsOpen(!isOpen)}
    const toggleAdd = async () => {
        if(!promoList) {
            let pl = await Eng001.loadPromoList()
            setPromoList(pl)
        }
        setAddWindow(!addWindow);
    }
    
    return <>
        {loading && <Wait1 />}
        {err && <Error1 />}
        {!data && !loading && <Button  outline onClick={loadData}>Промоматеріали...</Button>}
        {data && <div>
                    <Button  outline onClick={toggle} >Промоматеріали</Button>
                    <Collapse isOpen={isOpen}>
                        <Card>
                            <CardHeader>
                                Промоматеріали
                                {IDORG && <Button  outline style={{float: 'right'}} onClick={toggleAdd}>{Emo.Add()}</Button>}
                            </CardHeader>
                            <CardBody>
                                <Table1 headers = {pH} initData= {data} fields = {pF} 
                                tableLineButton ={Emo.Edit1()} 
                                tableLineButtonFun={editPromo}
                                tableLineButtonFunKeyFld={'ID'}
                                
                                />
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
        }

        <Modal isOpen={addWindow} toggle={setAddWindow}>
            <ModalHeader toggle={toggleAdd}>Видача проматеріалів</ModalHeader>
            <ModalBody>
                {!promoList && <Wait1 />}
                { promoList && <Table1 
                    headers = {addH} initData= {promoList} fields = {addF} filterOpt={1} 
                    tableLineButton={'+'} tableLineButtonFun={addPromo} tableLineButtonFunKeyFld={'IDPROMOM'}
                    />}
            </ModalBody>
      </Modal>



    </>
}
export default PromoCli
