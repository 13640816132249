//редагування перс даних лікаря або співробітника аптеки
import React from "react";
import { Button } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import Form1 from "../../comcom/Form1";
import { toInteger } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001";
import { usePageData1 } from "../../comcom/PageData1"

const EmplEdit1 = (props) =>{
    const IDORG = toInteger(props.IDORG)
    const IDORGSOTR = toInteger(props.IDORGSOTR)
    const FUN = props.FUN
    const IFLIKAR= props.IFLIKAR //0-спиівроб.аптеки 1 - лікар
    const EptyData = {IDORGSOTR:IDORGSOTR,IDORG:IDORG,DOLGN:'',NAME1:'',TEL:'',BIRTHDAY:'',SEX:'',IFWORK:'Y'}

    const [dolgnList,setDolgnList] = React.useState([])

    const loadDolgnList = async () =>{
        if(dolgnList.length===0){
            const d1 = await Eng001.loadEmplDOLGN()
            dolgnList.push(d1.length)
            let d2 =[]
            for(let i=0;i<d1.length;i++){ 
                if(IFLIKAR==1 && d1[i]['VR']==1)  d2.push(d1[i]['DOLGN']) 
                if(IFLIKAR==0 && d1[i]['SOTRTT']==1)  d2.push(d1[i]['DOLGN']) 
            }
            setDolgnList(d2)
        }
    }
    const loadData = async ()=>{
        await loadDolgnList()
        if(IDORG>0 && IDORGSOTR===0){
            return EptyData
        }else{
            let d1 = await Eng001.loadEpmlData({IDORG : IDORG, IDORGSOTR : IDORGSOTR})
            if(d1[0].SEX === 'Ms') d1[0].SEX = 'Ж'
            return d1[0]
        }

    }



    const [data,loading,err,fLoadData,setNewData,setloading] =  usePageData1(loadData)


    const saveData = async (newD) =>{
        newD = newD.formData
        newD.IDORG = IDORG
        newD.IDORGSOTR = IDORGSOTR
        newD.IFLIKAR = IFLIKAR
        setloading(true)
        let r = await Eng001.saveEmpl(newD)
        if (r > 0) FUN() 
    }

    const fields = [
        {fname:'NAME1',ftype :'text',fcaption:'ПІБ',fhint:'ПІБ',fnotNull:true,fval:data.NAME1},
        {fname:'DOLGN',ftype :'select',fcaption:'Посада',fhint:'посада',fnotNull:true,fval:data.DOLGN,fvals:dolgnList},
        {fname:'LL',ftype :'select',fcaption:'Лояльність',fhint:'',fnotNull:true,fval:data.LL,fvals:['',1,2,3]},
    //    {fname:'L1',ftype :'line'},
        {fname:'TEL',ftype :'text',fcaption:'Телефон',fhint:'тел',fnotNull:false,fval:data.TELPERS},
        {fname:'EMAIL',ftype :'email',fcaption:'E-mail',fhint:'ел.адреса',fnotNull:false,fval:data.EMAILPERS},
        {fname:'IFWORK',ftype :'select',fcaption:'Працює',fhint:'працює',fnotNull:false,fval:data.IFWORK,fvals:['Y','N']},
        {fname:'SEX',ftype :'select',fcaption:'Стать',fhint:'стать',fnotNull:false,fval:data.SEX,fvals:['Ч','Ж','']},
        {fname:'BIRTHDAY',ftype :'date',fcaption:'День народження',fhint:'',fnotNull:true,fval:data.BIRTHDAY},
    ] 
    const formCFG = {
        buttonCaption : "Зберегти",
        //cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : saveData,
        //actionCancel : togglePWD,
        //clearData : true,
        //localStorageTag : '',
    }   
    
    

    return <>
        
        {loading && <Wait1 />}
        {err && <Error1 />}
        {data && <div>
            <Form1 formCFG={formCFG}  />
        </div>}
        Edit Empl {IDORG} {IDORGSOTR}
    </>
}


export default EmplEdit1