import React from "react"
import { Collapse, Button, CardBody, Card, Form, Input 
,CardHeader,CardText} from 'reactstrap';

import {dateTimeStrToStr1} from "../../comcom/ComFunc"

import Emo from "../../comcom/Emo";
import Eng001 from "../../engine/eng001"
import ShowGMap1 from '../../comcom/GMap1'
import WortType from "./WorkType";
import Wait1 from "../../comcom/Wait1";

const Work1Simple =  (props) => {
    const [ifMap,setMap] = React.useState(false)
    const [isOpen1, setIsOpen1] = React.useState(false);
    const [loading,SetLoading] = React.useState(false)
    const toggle1 = () => setIsOpen1(!isOpen1);


    let idjob =  0 
    let idjobtp = props.IDJOBTP
    let txt = ''
    let dtfix = ''
    let latitude = props.latitude
    let longitude = props.longitude
    let capt = props.capt
    let updateWorkData = props.updateWorkData
    let canEdit = true
    
    if(props.job){
        idjob = props.job.IDJOB ? props.job.IDJOB : 0 
        //idjobtp = props.job.IDJOBTP
        txt = props.job.TXT
        dtfix = props.job.DTFIX
        if(idjob>0){
            latitude = props.job.GEOX
            longitude = props.job.GEOY
        }
    }
    const btnMapClick = ()=> {
        if(!latitude) setMap( false)
        else setMap(!ifMap)
    }
    let addSimpleWork = !props.job && idjobtp == 2

    const saveJob = async (e) =>{
        e.preventDefault()
        try{
           SetLoading(true)
            const r = await Eng001.seveSimpleWork(idjob,idjobtp,txt,latitude,longitude)    
            updateWorkData(r)
            setIsOpen1(false)
        }finally{
            SetLoading(false)
        }
    }
    let txtAll = ''
    let txtWork = ''
    const ifdone = idjob > 0
    if(idjob >0){
        txtAll = dateTimeStrToStr1(dtfix)
        if(idjobtp == 2){
           if(txt.letght < 10) txtAll += '  '+txt
           else txtAll += '  '+txt.substring(0,10)+'...'
        }
    }else{
        if(!addSimpleWork) txtAll = '(не виконано)'
    }
    const onChange = event =>{
        txt = event.target.value
    }  
    const points = [
        {lat: latitude, lng: longitude, title : 'Відмічено',label : '!!'},
    ]

// {addSimpleWork && Emo.Add()}    
if(loading) return <Wait1 />
return<>
<Card  color="primary"  outline    >
<CardHeader onClick={toggle1} style={{cursor: "pointer"}}>   
        <WortType OK={ifdone} TP={capt} DT={txtAll} />   
</CardHeader>
       {addSimpleWork && Emo.Add()}
        
            <Collapse isOpen={isOpen1}>
            <Card>
                <CardBody>
                    <Form>
                        <Input onChange={onChange} placeholder="опис/примітка" defaultValue={txt} />
                        <Button color="primary" outline onClick={saveJob}>{Emo.Save1()}</Button>
                        {latitude>0 && <Button  color="info"   outline onClick={btnMapClick}> {Emo.Look1()}  </Button>}
                    </Form>
                </CardBody>
            </Card>
            {ifMap&& <ShowGMap1 points={points}/> }
            </Collapse>
            </Card>            
            </>


}

export default Work1Simple

