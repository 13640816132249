
const Styles1 = {
    styleBold     : () => {return { fontSize:16, fontWeight:"bold" }},
    styleBold_U     : () => {return { fontSize:16, fontWeight:"bold", textDecoration:"underline" }},
    styleBoldBLUE : () => {return {color:"blue", fontSize:16, fontWeight:"bold" }},
    styleBoldBLUE_U : () => {return {color:"blue", fontSize:16, fontWeight:"bold", textDecoration:"underline" }}
}

export default Styles1


