import React, { useRef, useState } from 'react';
import {Button,Card,CardHeader,CardBody,Input,Progress} from "reactstrap"
import axios from 'axios';

import Emo from '../comcom/Emo';

import appData from '../AppData';
import Error1 from '../comcom/Error1';
import { ComConst } from '../WTConst';

function FileUpload(props) {

  // для хранения загруженного файла
  const [file, setFile] = useState('');
  
  // для хранения ответа от бекенда
  const [data, getFile] = useState({ name: "", path: "" });

  const [progress, setProgess] = useState(0); // progessbar
  const el = useRef(); // для доступа к инпуту

  const handleChange = (e) => {
    setProgess(0)
    const file = e.target.files[0]; // доступ к файлу
    setFile(file); // сохранение файла
  }

  if(!props.IDFILE){return <Error1 msg='Не вказано ідентифікатор файлу...'/>}
  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file); // добавление файла
    formData.append("IDFILE", props.IDFILE);
    formData.append("token",  appData.getToken())
    formData.append("version",  appData.getVersion())    

    const url = ComConst.SERVERPATH+'/api/m000/uploadFile'
    //    'http://192.168.2.210:8081/api/m000/uploadFile'
    axios.post(url, formData, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100
        ) //+ '%';
        setProgess(progress);
      }
    }).then(res => {
      setProgess(0);
    }).catch(err => alert(err))
  }
  let ifprogress = (progress != 0)
  return (
    <Card>
      <CardHeader>Завантажити/Змінити файл</CardHeader>
      <CardBody>
        <Input type="file" ref={el} onChange={handleChange} />
          {ifprogress &&   <Progress  max="100"  value={progress} />}
        <Button onClick={uploadFile}  color="primary" outline>
          Завантажити
        </Button>
        </CardBody>  
    </Card>
  );
}

export default FileUpload;