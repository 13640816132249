import React from "react";
import { Button,Card,CardHeader,CardBody } from "reactstrap";

import Emo from "../../comcom/Emo";
import {dateTimeStrToStrTime} from "../../comcom/ComFunc";
import ShowGeoPosition2 from "../ShowGeoPosition2";
import DoctorInfo1 from "../Doctor/DoctorInfo1";
import PharmacyInfo1 from "../Pharmacy/PharmasyInfo1";


const Work2dd = (param) =>{
    const [dopInfo,setDopInfo] = React.useState(false)
    let w = param.w
    const toggleInfo = () =>{
        setDopInfo(!dopInfo)
    }
    //console.log(w)
    return (
    <Card  color="primary"  outline    >
    <CardHeader>   
    {w.DTFIX && <>{Emo.Ok1()}</>}{' '}
    {w.DTFIX && <>{dateTimeStrToStrTime(w.DTFIX)}</>}
    {!w.DTFIX && <>{w.VTIME}</>}
    {' '}{w.JOBNAME}{' '}
    {w.VR && <>{w.VR} {w.VRACHSPEC}</>}{' '}
    {!w.VR && <>{w.VR} {w.ORGNAME}</>}
    {w.TXT}
    </CardHeader>
    <CardBody>
        {w.VR && <> {w.ORGNAME}</>}{' '}
        {w.ADR}
        <ShowGeoPosition2 lat1={w.GEOX} long1={w.GEOY}  capt1='Тут' lat2={w.GEOX_SPR} long2={w.GEOY_SPR}  capt2='Справ' error={!w.GEOY} showDist={1}/>
        {w.VR &&  
            <>
            <Button color="secondary" size="sm" outline onClick={toggleInfo} >Докладніше про лікаря...</Button> 
            {dopInfo && <>
                <hr />
                <DoctorInfo1 IDORGSOTR={w.IDORGSOTR} RefreshButton={''}/>
            </>}
            </>
        }
    {!w.VR && <>
        <Button color="secondary" size="sm" outline onClick={toggleInfo} >Докладніше про аптеку...</Button> 
        {dopInfo && <>
            <hr />
            <PharmacyInfo1 IDORG={w.IDORG} />
        </>}
        </>
        }
    </CardBody>
    </Card>
    )
}

export default Work2dd