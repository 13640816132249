import React from "react";
import { useNavigate } from "react-router-dom";

import CenterLayout from '../comcom/CenterLayout'
import Form1 from "../comcom/Form1";
import Wait1 from "../comcom/Wait1";
import Emo from "../comcom/Emo";

import appData from "../AppData";

let errCount = 0

const PageLoginForm = props => {
    const navigate = useNavigate()
    const [st,setST] = React.useState(0)


    //0,1,2,3 - чекаємо, перевіряємо, помилка, все ок
    const fields = [
        {fname:'Login',ftype :'text',fcaption:'Login',fhint:'input your login ...',fval:'',fnotNull:true},
        {fname:'PWD',ftype :'password',fcaption:'Password',fhint:'input your password ...',fval:'',fnotNull:true},
    ] 
    const action = async (v) =>{
        setST(1)
        const rez = await appData.login(v.formData.Login,v.formData.PWD)
        if(rez.result == 'Error') {
            errCount = errCount + 1
            setST(2)
        }else{
            navigate('/')
        }

    }    

    if(st === 1) return  (
        <CenterLayout>
           <Wait1 />     
        </CenterLayout>
    )
    const formCFG = {
        caption : "Реєстрація...",
        buttonCaption : "Login",
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : action
    }    

    if(st === 2) {
        formCFG.formErrorMsg = 'Помилка реєстарції '+'.'.repeat(errCount);
    }
    return (
        <CenterLayout>
            <Form1 formCFG={formCFG}  />     
            <hr/>
            <p style={{fontSize:10}}>
                
             {Emo.Exclamation()}
                Для перегрузки програми (наприклад для отримання актуальної версії) 
                "проведіть пальцем зверху до низу" при використанні на смартфоні,
                 або натисніть кнопку перезавантажити сторінку в браузері
                 {Emo.Refresh1()}
                
            </p>

        </CenterLayout>
    )
}

export default PageLoginForm