import React from "react";
import Emo from "../../comcom/Emo";

const WortType = (props) => {
    const TP = props.TP
    const Ok = props.OK
    const DT = props.DT
    return  <>
                {Ok && Emo.Ok1()}
                {!Ok && Emo.Empty1()}  
                {' '}<span style={{color:"blue",fontWeight:'bold',fontSize:14 }}>{TP} </span>
                {' '}{DT}
    </>
}

export default WortType