import Eng001 from "../../engine/eng001";
import TableSecondaryData from "../../comcom/TableSecondaryData";


const Pharm3plus1 = ({IDORG}) =>{

    const loadData = async ()=>{
        let d = await Eng001.load3plus1({IDORG})
        return d
    }
    const addToAction = async ()=>{
        return await Eng001.addTo_3_plus_1({IDORG})
    }


    const pH=['Рік','Місяць','Підтв','Приз','Кільк']
    const pF=['YY','MM','DTPODTV2','SHORT_NAME','KOL_V']   

    return <>
        <TableSecondaryData caption={'Акції ДМ'}
            loadDataFun={loadData}
            Param={{IDORG}}
            Fields={pF}
            ColumnCaption={pH}
            DodButtonCaption={'+в поточн місяць'}
            DodButtonAction = {addToAction}
        />
    </> 
}

export default Pharm3plus1