import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import {Button} from "reactstrap";


import appData from "../AppData";

import { CachingTag,remoteDayWorkCash } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";

import RedirectToLogin from '../components/RedirectToLogin'
import Table1AndFilter from "../comcom/Table1AndFilter";
import Table1 from "../comcom/Table1";
import Eng001 from "../engine/eng001";
import { NetShemottshnic } from "../components/Pharmacy/NetShemotehnic";
import { usePageData1 } from "../comcom/PageData1";
import Wait1 from "../comcom/Wait1";



const NetContrD = props =>{
    let curNet = props.curNet
    const loadData = async =>{
        try{
            return Eng001.loadNetTTPoObl({IDNCONTR : curNet.IDNCONTR})
        }catch(err){
            return false
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)
    const Theaders = ['Регіон','Обл','Кільк.ТТ']
    const Tfields = ['DISTR','OBL','KOLTT']

    return <>
            <br /> КОД : <b>{curNet.IDNCONTR}</b>
            <br /> Мережа : <b>{curNet.TXT}</b>
            <br /> KAM : <b>{curNet.NAME1}</b>
            <br /> ТИП : <b>{curNet.NN}</b>
            <br /> АВС : <b>{curNet.ABC}</b>
            <br />
            <br /><NetShemottshnic IDNCONTR={curNet.IDNCONTR} />
            <br />
            <br />TT ПО ОБЛАСТЯМ:
            {loading && <Wait1 />} 
            {data && data.length > 0 && <>

                <Table1 headers = {Theaders} 
                        fields = {Tfields} 
                        initData = {data} 
                        filterOpt={false}
                        />            
            </>}

    </>
}







const PageNetContr = props => {
    const [curNet,setCurNet] = React.useState(false);
    const loadData = async (ff) =>{
        if((!ff.TXT) && (!ff.KAM) && (!ff.DISTRICT)) {
        }
        try{
            let d = await Eng001.loadNetContr(ff)
            return d
        }catch(err){
            return false
        }
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.NetContr,loadData)
    const [currRowInTable,setCurrRowInTable] = React.useState(0)
    const location = useLocation();
    let pAdd = location.state; //IDSOTR, DD,SOTR - параметри додавання в роботи - код дата ім'я 
    let header = ''
    let IDTASKTP = 0
    let tableLineButton = ''
    if(pAdd == null) pAdd = false    
    else {
        header = 'Створюємо '+pAdd.TPTXT
        IDTASKTP = pAdd.IDTASKTP
        tableLineButton = 'Вибрати'
    }
    
    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )

    const ActionInTable = (row,fn,rowNN) => {
        setCurNet(row)
    }    
    const buttonProc = async (id) => {
            if(pAdd.TP==='ADDTOWORK'){
                let p = {IDSOTR:pAdd.IDSOTR,DD:pAdd.DD,ID:id,IDTASKTP:IDTASKTP}
                let r = await Eng001.addRMWork(p)
                if(r>0) {
                    remoteDayWorkCash()
                    alert("Ok")
                }
            }            
    }

    const fieldsFilterForm = [
        {fname:'TXT',ftype :'text',fcaption:'Мережа',fhint:'',fnotNull:false,fval:''},        
        {fname:'KAM',ftype :'text',fcaption:'KAM',fhint:'',fnotNull:false,fval:''},
        {fname:'DISTRICT',ftype :'text',fcaption:'Регіон',fhint:'',fnotNull:false,fval:''},
    ] 
    const Theaders = ['Мережа','Тип','АВС','КАМ','Регіон']
    const Tfields = ['TXT','NN','ABC','NAME1','DISTRICT']

    const goBack = () => setCurNet(false)
    
    if(curNet){
        return <>
            <Button color="primary"  outline onClick={goBack}>Назад</Button>
            <NetContrD curNet = {curNet} />
        </>
    }
    
    return (
        <>
        {header && <><h5>{header}</h5></>}   
        <Table1AndFilter 
            formHeader={'Мережі'} 
            fieldsFilterForm = {fieldsFilterForm}
            loadDataFromServer = {funLoadData}
            Theaders = {Theaders} 
            Tfields = {Tfields} 
            fieldsForAction={"TXT"}
            actionInTable={ActionInTable} 
            filterOpt="1" 
            currRowInTable={currRowInTable}    
             setCurrRowInTable={setCurrRowInTable}           
            data = {data}
            loading = {loading}
            err ={err}
            tableLineButton = {tableLineButton}
            tableLineButtonFun = {buttonProc}
            tableLineButtonFunKeyFld={'IDNCONTR'}
            
        />            
        
        </>        
    )
}

export default PageNetContr