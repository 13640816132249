import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  ButtonGroup
} from 'reactstrap';

import logo from '../logo.png'
import Emo from '../comcom/Emo';
import appData from '../AppData'
import RedirectToLogin from './RedirectToLogin';




function Nav1(props) {
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(true);


  const toggleNavbar = (p=false) => {
    if(p) setCollapsed(true)
    else  
    setCollapsed(!collapsed)
  }

  if (!appData.loginOk())
    return(
      <div>
      <Navbar   className='mb-2 bg-light text-dark border-bottom border-dark'>
        <NavbarBrand href="*" className="me-auto">
        <img src={logo} alt="Movi Health" height="40" />
          {' '}<span style={{color:'coral'}}>MoviHealth</span>
        </NavbarBrand>
      </Navbar>
    </div>
  );

  /*
        <NavbarBrand href="/" className="me-auto">
        <img src={logo} alt="Movi Health" height="40" />
          {' '}<span style={{color:'coral'}}>MoviHealth</span>
        </NavbarBrand>


  */

  const menuProc = llink => {
    toggleNavbar()
    navigate(llink)
  }
  const logOut = () =>{
    let r = window.confirm('Вийти з програми?')
    if( r ){
        appData.logOut()
        navigate('/')
    }
}

  const mnu = appData.data.mnu
  const mnuWork = mnu.find(m => m.FNUMBER==100)
  let mnuFavor = mnu.filter((m)=>m.FAVOR==='1')


  return (
    <div>
      <Navbar   className='mb-2 bg-light text-dark border-bottom border-dark'>
      <Button     color="link"  outline onClick={()=>{toggleNavbar(1);navigate('/mainForm')}}> 
          <img src={logo} alt="Movi Health" height="40" />
          {' '}<span style={{color:'coral'}}><b>MoviHealth</b></span>
       </Button>
       <ButtonGroup>
       

        {mnuWork &&
        <Button key={9999} outline 
            onClick={()=>navigate(`/mnu/100`)}>  
               Робота
        </Button>
        }
        <Button  outline
            onClick={logOut}>
              {Emo.Cancel1()}
        </Button>
        {' '}
        {' '}
       
        <NavbarToggler onClick={()=>toggleNavbar(0)} className="me-2" />
        </ButtonGroup>
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
             {mnuFavor.map((m,idx) => 
                <NavItem key={idx}>
                  <Button  color="link" onClick={()=>menuProc(`/mnu/${m.FNUMBER}`)}>{m.FNAME}</Button>
                </NavItem>
             )}
             {mnu.map((m,idx) => {
                if(m.FAVOR !== '1') return (
                <NavItem key={1000+ idx}>
                  <Button  color="link" onClick={()=>menuProc(`/mnu/${m.FNUMBER}`)}>{m.FNAME}</Button>
                </NavItem>
                )
              })}

             <NavItem>
              <Button color="link" onClick={()=>menuProc('/user')}> User </Button>
            </NavItem>  
            <NavItem>
              <Button color="link" onClick={()=>menuProc('/TestCoordinat')}>Тест координат</Button>
            </NavItem>  

            
          </Nav>
        </Collapse>
      </Navbar>



    </div>
  );
}
//<NavItem>
//<Button color="link" onClick={()=>menuProc('/work1')}> Робота </Button>
//</NavItem>




export default Nav1;