import React from "react";
import {Container,Col,Row} from 'reactstrap'

import Nav1 from "./Nav1";
import Menu1 from "./Menu1";

const Layout = (props) => {
    return (
    <Container>
        <Nav1 />
        <Row>
            <Col className="d-none d-md-block col-md-2  border-end border-dark"> 
                <Menu1 />
            </Col>
            <Col className="col-md-9 col-sm-12">
                {props.children}
            </Col>
            <Col className="d-none d-md-block col-md-1 "></Col>
        </Row>
    </Container>  

    )
}
export default Layout