import Engine from "./engine";


class Eng000 {
    static async login(login,pwd,vers){
        //alert("Eng000/login vers = " + vers)
        return await Engine.postData('m000/login',{login:login,pwd:pwd,version:vers});
    }
    static async testSession(){
        const rez = await Engine.postData('m000/testconnection',{}) 
        if (!rez.errcode){ //error перевіряє та алярміт postData
            alert('Connection - OK')
        }
    }
    static async changePWD(old_pwd,new_pwd1,new_pwd2){
        if(old_pwd === new_pwd1 || new_pwd1 !== new_pwd2 || new_pwd1 === '') {
            return false;
        }
        const rez = await Engine.postData('m000/changepwd',{old_pwd:old_pwd,new_pwd : new_pwd1})
        return !rez.errcode
    }
    static async sendFile(param){
        return await Engine.postData('m000/saveFile',param);
    }

    

}

export default Eng000

