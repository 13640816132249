import React from "react";
import { Alert } from "reactstrap";

const Alert1 = props => {
    const {type,msg,canClose} = props
    const [visible, setVisible] = React.useState(true);
    const onDismiss = () => setVisible(false);

    if(canClose) {
        return( 
            <React.Fragment>
                {msg && (<Alert color={type} isOpen={visible} toggle={onDismiss}>{msg}</Alert>)}
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {msg && (<Alert color={type} >{msg}</Alert>)}
        </React.Fragment>        
    )

}

export default Alert1