/*
    сторінка щоденної роботи попередньо для зовнішньої служби
*/
import React, { useState,useEffect } from "react";
import { Button,ButtonGroup,Dropdown,DropdownToggle,DropdownMenu,DropdownItem} from 'reactstrap';
import { useNavigate } from "react-router-dom"

import Emo from "../comcom/Emo";
import appData from "../AppData";
import Eng001 from "../engine/eng001";

import RedirectToLogin from '../components/RedirectToLogin'
import {usePosition} from '../comcom/geo1'
import ShowGeoPosition from "../components/ShowGeoPosition";
import Work1Simple from "../components/Work1/Work1Simple";
import Work34 from "../components/Work1/Work34"
import {dateTimeStrToStr,dateStrWEB,NowToStr} from "../comcom/ComFunc";
import Wait1 from "../comcom/Wait1";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";
import { RoutPath } from "../WTConst";
import Error1 from "../comcom/Error1";
import Avto1 from "../components/Work1/Avto1";
import Work7 from "../components/Work1/Work7";




const PageWork1 = (props) => {

    const WorkDD = props.WorkDate? props.WorkDD : dateStrWEB( new Date())
    const WorkIDSOTR = props.WorkIDSOTR? props.WorkIDSOTR : appData.data.idsotr


    const navigate = useNavigate()

    const loadData = async function(){
        try{
            let wd = await Eng001.getWorkData(WorkIDSOTR,WorkDD)
            wd.IDSOTR = WorkIDSOTR
            wd.WorkDD = WorkDD
            return wd
        }catch(err){
            return false
        }
    }
    const testCashData = function(cashData){
        try{
            if(!cashData) return false
            if(!cashData.data) return false
            let r = cashData.data.IDSOTR==WorkIDSOTR 
                    && cashData.data.WorkDD == WorkDD 
            return r
        }catch(err){
            return false
        }
    }


    let [workData,loading,err,funLoadData,setNewData] = usePageDataCashedWT1('WT_WORK1_DATA',loadData,testCashData)
    const { latitude, longitude, wait, error } = usePosition();

    const [mnuAdd, setMnuAdd] = useState(false);
    const toggleMnuAdd = () => setMnuAdd((mnuAdd) => !mnuAdd);    


    const updateWorkData = (data1) =>{
        if(!data1) return
        let newWorkData = undefined
        if(data1.IDJOBTP == 9){
            newWorkData = {...workData,beginDay:data1}
        } 
        if(data1.IDJOBTP == 1){
            newWorkData = {...workData,startWork:data1}
        } 
        if(data1.IDJOBTP == 2){
            let works = workData.works
            if(data1.new){
                works.push(data1)
                newWorkData = {...workData,works:works}
            }else{
                for(let i=0;i<works.length;i++){
                    if(works[i].IDWORKTP==data1.IDWORKTP && works[i].IDJOB==data1.IDJOB ){
                        works[i] = data1
                        newWorkData = {...workData,works:works}
                        setNewData(newWorkData)
                        return
                    }
                }
            }
            
        } 

        if(newWorkData){
            setNewData(newWorkData)
        }
    }

    const refreshWorkDataBtn = async () =>{
        await funLoadData(false)
    } 

    const addSimpleWorkAsk = async () => { await addSimpleWork() }
    const addSimpleWorkParkin = async () => { await addSimpleWork('Парковка') }

    const addSimpleWork = async (txt) => {
        if(!txt) txt = prompt("Проста робота, введіть опис:");
        if(txt){
            const r = await Eng001.seveSimpleWork(0,2,txt,latitude,longitude)  
            r.new = true
            updateWorkData(r)        

        }
    }



    if(!appData.loginOk()) return  <RedirectToLogin />
    if(loading || !workData || !workData.user) return (<>
        <Button  color="primary" outline onClick={refreshWorkDataBtn}>Оновити</Button>
        <rb />
        <Wait1 /> 
        </>)
    if(err) return (<>
        <Button  color="primary" outline onClick={refreshWorkDataBtn}>Оновити</Button>
        <rb />
        <Error1 /> 
        </>)

    let paramsAdd = false
    if(WorkIDSOTR == appData.data.idsotr)  
      paramsAdd = {TP:'ADDTOWORK', 
                   IDSOTR:WorkIDSOTR, 
                   DD:WorkDD, 
                   SOTR: workData?.user?.NAME1
                }
    const addVizitDoLikary = async () =>{navigate(RoutPath.Doctor1,{state : paramsAdd }) }
    const addVizitDoApt = async () =>{   navigate(RoutPath.Pharmacy1,{state: paramsAdd}) }
    const addAdminWork = async () =>{    paramsAdd.IDTASKTP=1;paramsAdd.IDWORK=0; navigate(RoutPath.Work1RM,{state: paramsAdd}) }
    //const addRMWork = async (IDTASKTP) => { paramsAdd.IDTASKTP=IDTASKTP; navigate(RoutPath.VizitsMP,{state: paramsAdd})}
    const addRMWork = async (IDTASKTP,TPTXT) => { 
        paramsAdd.IDTASKTP=IDTASKTP
        paramsAdd.TPTXT=TPTXT
        if(IDTASKTP==7){navigate(RoutPath.NetContr,{state: paramsAdd}) }
        else{    navigate(RoutPath.VizitsMP,{state: paramsAdd}) }
    }
     

    const refrAvtoData = ( nAvto ) => {  const wd1 = {...workData,avto : nAvto};  setNewData(wd1) }
      
        
        
    const RMMENU = true 
    
    const addCheckIn = async () =>{
        await addSimpleWork('Відмітка "Я тут!"')
        //alert('checkIn')
    }    

    let RMMNU = [];
    
    if(!appData.IFMP() || appData.testRights(177)){
        RMMNU = [
           // {IDTASKTP :1,TASKTP : 'Адмін робота'},
            {IDTASKTP :2,TASKTP : 'ПВ аптека'},
            {IDTASKTP :8,TASKTP : 'ПВ лікар'},
            {IDTASKTP :4,TASKTP : 'МВ аптека'},
            {IDTASKTP :15,TASKTP : 'МВ лікар '},
           // {IDTASKTP :5,TASKTP : '(КВ) Коуч.візит'},
            {IDTASKTP :7,TASKTP : '(Мережа) КАМ зустріч'},
            {IDTASKTP :10,TASKTP : 'Конференція'},
           // {IDTASKTP :11,TASKTP : 'СБ контроль'},
            
        ]
    }
    
    let kolApt=0,kolAptOk=0,kolVr=0,kolVrOk=0
    for(let i=0; i<workData.works.length; i++){
        if(workData.works[i]['IDJOBTP'] == 3){
            kolApt++;
            if(workData.works[i].DTFIX != '') kolAptOk++
        }

        if(workData.works[i]['IDJOBTP'] == 4){
            kolVr++;
            if(workData.works[i].DTFIX != '') kolVrOk++
        }
    }
    let statStr = '';
    if(kolApt>0 || kolVr >0){
        statStr = `Всього ${kolApt}/${kolAptOk} аптек, ${kolVr}/${kolVrOk} - лікарів `
    }
    

    return (
        <div>
            <h5>{dateTimeStrToStr(WorkDD)} {' '} {workData.user.NAME1} {' '} {workData.user.OBL} </h5>
            <ButtonGroup>
                <Button  color="primary" outline onClick={refreshWorkDataBtn}>Оновити</Button>
                
                {paramsAdd && <>
                <Button  color="primary" outline onClick={addCheckIn}>{Emo.Pin1()}CheckIn!</Button>
                <Dropdown isOpen={mnuAdd} toggle={toggleMnuAdd} >
                    <DropdownToggle  color="primary" outline>{Emo.Add()}Додати</DropdownToggle>
                    <DropdownMenu>
                       <DropdownItem>
                            <Button  color="link" outline onClick={addSimpleWorkAsk} > {Emo.Add()} "проста" (адм) робота</Button>
                        </DropdownItem>                    

                        <DropdownItem>
                            <Button  color="link" outline onClick={addVizitDoLikary}>{Emo.Add()}Лікар</Button>
                        </DropdownItem>                    
                        <DropdownItem>
                            <Button  color="link" outline onClick={addVizitDoApt}>{Emo.Add()}Аптека</Button>
                        </DropdownItem>

                        {RMMENU && <>
                            {RMMNU.map((m)=>(
                                <DropdownItem>
                                    <Button  color="link" outline onClick={()=>addRMWork(m.IDTASKTP,m.TASKTP)}>{Emo.Add()}{m.TASKTP}</Button>
                                </DropdownItem>
                            ))}
                        </>}
                    </DropdownMenu>
                </Dropdown>     
                </>
                }       
            </ButtonGroup>
                <br /><br />
                

            <Avto1 AVTO={workData.avto} avtoMRSR={workData.avtoMRSR} refrAvto={refrAvtoData}
                DD = {WorkDD} IDSOTR = {WorkIDSOTR} addParking={addSimpleWorkParkin}
            
            />
            

            <Work1Simple job={workData.beginDay} IDJOBTP={9} capt= "Вихід на роботу " updateWorkData={updateWorkData} />
            
            <Work1Simple job={workData.startWork} IDJOBTP={1} capt="Початок роботи " updateWorkData={updateWorkData} latitude={latitude} longitude ={longitude}/>            
            {statStr}   
            {
                workData.works.map((w,i)=>{
                    if(w.IDJOBTP == 2) return (
                        <Work1Simple job={w} IDJOBTP={2} capt= "Інше... " updateWorkData={updateWorkData} />
                    )
                    if(w.IDJOBTP == 7) return (
                        <Work7 job={w} IDJOBTP={7}  updateWorkData={updateWorkData} />
                    )                    
                    return (
                    
                    <div key ={i} >
                        <Work34 job={w} MP={workData.user.NAME1}/>    
                    </div>
                )})
            }
            <hr />
            <ShowGeoPosition latitude={latitude} longitude ={longitude} wait={wait} error={error} />
        </div>        
    )
}

//<br />
//<Work1Simple capt="Початок роботи"/>
//<Work1Simple job={undefined} IDJOBTP={2} capt="Додати 'просту' роботу" updateWorkData={updateWorkData} latitude={latitude} longitude ={longitude}/>            
//
//{RMMENU && <DropdownItem>
//    <Button  color="link" outline onClick={addAdminWork}>{Emo.Add()}Адмін робота</Button>
//</DropdownItem>}




export default PageWork1