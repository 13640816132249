import React from "react"
import { Button,Card,CardBody,CardHeader,CardText,CardSubtitle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { RoutPath } from "../../WTConst";
import Emo from "../../comcom/Emo";
import {dateTimeStrToStr} from "../../comcom/ComFunc"
import WortType from "./WorkType";

const Work7 = (props) =>{
    const navigate = useNavigate()
    const IDWORK = props.job.IDWORK
    const IDTASKTP = props.job.IDTASKTP
    const IDJOB = props.job.IDJOB? props.job.IDJOB : 0 
    const JOBNAME = props.job.JOBNAME? props.job.JOBNAME : 0 
    const DTVIZIT = props.job.DTVIZIT
    let datevizit = dateTimeStrToStr( props.job.DATEVIZIT ) //?DTFIX
    const DTFIX = props.job.DTFIX? props.job.DTFIX : false
    if(DTFIX) datevizit = dateTimeStrToStr( props.job.DTFIX )
    let adr = props.job.ADR
    let org = props.job.ORGNAME
    if(IDTASKTP==7){
        org = props.job.NET
        adr = ''
    }
    const vr =  props.job.VR
    const vrspec = props.job.VRACHSPEC
    const txt = props.job.TXT


    const EditJob = async ()=>{
        const param = {
            TP:'EDITWORK', 
            IDTASKTP : IDTASKTP,
            IDWORK: IDWORK
        }
        navigate(RoutPath.Work1RM,{state: param}) 
    }

    const ifdone = IDJOB > 0 
    return <>
         <Card  color="primary"  outline  >
            <CardHeader  onClick={EditJob} style={{cursor: "pointer"}}>      
                <WortType OK={ifdone} TP={JOBNAME} DT={datevizit} />   {txt}        
            </CardHeader>
            <CardText style={{ margin:"0px 1px 0px 40px"}}>
                <span>{vr} {vrspec}  <u>{org}</u> {' '}
                {adr} </span>
            </CardText>
        </Card>    
    
    
    </>
}

export default Work7