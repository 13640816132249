import React from "react"
import { Button } from "reactstrap"

import DoctorInfo1 from "./DoctorInfo1"

import { toInteger } from "../../comcom/ComFunc"


const Doctor2 = (props) =>{
    const IDORGSOTR = toInteger(props.IDORGSOTR)
    const funcBack = props.funcBack
    return <>
    <Button color="primary" outline onClick={funcBack}>Назад </Button>
    <DoctorInfo1 IDORGSOTR={IDORGSOTR} />
    </>
}

export default Doctor2