export const toInteger = (v)=>{
    let iv = Number(v)
    if(Number.isInteger( iv )) return iv
    return false
}

export const isStrDate = (s) => {
    if (typeof s !== 'string') return false
    //2023-12-28 00:00:00.000
    //12345678901234567890123
    //2023-12-28 00:00:00
    //1234567890123456789
    //2023-12-28
    //1234567890
    if(!(s.length === 23 || s.length === 19 || s.length === 10)) return false
    const s1='./-:'
    const s2='0123456789 '
    //повинно бути символи з 
    let s1ok=false,s2ok=false
    for(let i=0;i<s.length;i++){
        if(!(s1+s2).includes(s[i])) return false
        if(s1.includes(s[i])) s1ok = true
        if(s2.includes(s[i])) s2ok = true
    }
    if(!s1ok || !s2ok) return false
    return true
}

export const isDate = (value) => {
    //https://jsfiddle.net/remarkablemark/7wegs1ao/

    //Date.parse('Медоборы Фарм, Аптека № 2') вертає 980978400000
    //що потім запросто перетворює в дату javascript-сила!!!!
    if(!value) return false
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) {
                if(!isStrDate(value)) return false
                return !isNaN(value.getTime());
            }
        default:
            return false;
    }
}

export const dateTimeStrToStr = (sdt) => {
    //2023-12-05 16:13:34.000 -> 05/12/23 16:13
    //12345678901234567890123
    if(!isStrDate(sdt)) return sdt
    let r = ''
    if(sdt.length>=10){
        r = sdt.substr(8,2) + '/'+sdt.substr(5,2)+'/'+sdt.substr(2,2)
    }
    if(sdt.substr(11,5) !== '00:00') r = r + ' '+sdt.substr(11,5)
    return r
}


export const dateTimeStrToStr1 = (dt) => {
    //2023-12-05 16:13:34.000 -> 05/12/2023 16:13
    //0123456789012345
    dt = String(dt)
    let r = ''
    if(dt.length>=15){
        r = dt.substr(8,2) + '/'+dt.substr(5,2)+'/'+dt.substr(0,4) + ' '+dt.substr(11,5)
    }
    return r
}
export const dateTimeStrToStr2 = (dt) => {
    //2023-12-05 16:13:34.000 -> 05/12/23 16:13
    //0123456789012345
    dt = String(dt)
    let r = ''
    if(dt.length>=15){
        r = dt.substr(8,2) + '/'+dt.substr(5,2)+'/'+dt.substr(2,2) + ' '+dt.substr(11,5)
    }
    return r
}
export const dateTimeStrToStrTime = (dt) => {
    //2023-12-05 16:13:34.000 ->  16:13
    //0123456789012345
    dt = String(dt)
    let r = ''
    if(dt.length>=15){
        r = dt.substr(11,5)
    }
    return r
}
export const dateTimeStrToStr3 = (dt) => {
    //2023-12-05 16:13:34.000 -> 05/12/23
    //0123456789012345
    dt = String(dt)
    let r = ''
    if(dt.length>=15){
        r = dt.substr(8,2) + '/'+dt.substr(5,2)+'/'+dt.substr(2,2)
    }
    return r
}

export const NowToStr = function( d = undefined){
    if( d === undefined)  d = new Date()
    let yy = toStr2(d.getFullYear(),4)
    let mm = toStr2(d.getMonth()+1)
    let dd = toStr2(d.getDate())
    let hh = toStr2(d.getHours())
    let mi = toStr2(d.getMinutes())
    let ss = toStr2(d.getSeconds())
    return dateTimeStrToStr2(yy+'-'+mm+'-'+dd+' '+ hh+':'+mi+':'+ss)
}

export const NowDateToStr = function( d = undefined){
    if( d === undefined)  d = new Date()
    let yy = toStr2(d.getFullYear(),4)
    let mm = toStr2(d.getMonth()+1)
    let dd = toStr2(d.getDate())
    return dateTimeStrToStr2(yy+'-'+mm+'-'+dd)
}


export const objectLength = (o) =>{
    let l=0
    for(let key in o) l++
    return l
}



export const dateTimeStrToStr4 = (dt) => {
    //2023-12-05 16:13:34.000 -> 16:13
    //0123456789012345
    dt = String(dt)
    let r = ''
    if(dt.length>=15){
        r = dt.substr(11,5)
    }
    return r
}

export const UnicString1= () =>{
    //довга майже унікальна строка з цифер
    //щось таке - '42287827000000481701788171109'
    return String(performance.now()).replace('.', '') + Date.now()
}

export const sleep = (ms) => {
    //uses :  await sleep(1000)
    return new Promise(resolve => setTimeout(resolve, ms));
}

const toStr2 = function(v,len=2){
    let s = v.toString()
    if (s.length < len) s = s.padStart(len,'0')
    return s 
  }

export const dateTimeToSqlStr = function( d = undefined){
    if( d === undefined)  d = new Date()
    let yy = toStr2(d.getFullYear(),4)
    let mm = toStr2(d.getMonth()+1)
    let dd = toStr2(d.getDate())
    let hh = toStr2(d.getHours())
    let mi = toStr2(d.getMinutes())
    let ss = toStr2(d.getSeconds())
    return yy+mm+dd+' '+ hh+':'+mi+':'+ss
}


export const dateToSqlStr = function( d = undefined){
  if( d === undefined)  d = new Date()
  let yy = toStr2(d.getFullYear(),4)
  let mm = toStr2(d.getMonth()+1)
  let dd = toStr2(d.getDate())
  return yy+mm+dd
}

export const dateToStr = function( d = undefined){
    if( d === undefined)  d = new Date()
    let yy = toStr2(d.getFullYear(),4)
    let mm = toStr2(d.getMonth()+1)
    let dd = toStr2(d.getDate())
    return dd+'/'+mm+'/'+yy
  }
  



  export const dateStrAllWEB = function(d = undefined ){
    //if( !d || !isDate(d) )
    //    d = new Date()
    if( !d  )  d = new Date()
    let yy = toStr2(d.getFullYear(),4)
    let mm = toStr2(d.getMonth()+1)
    let dd = toStr2(d.getDate())
    let hh = toStr2(d.getHours())
    let mi = toStr2(d.getMinutes())
    let ss = toStr2(d.getSeconds())
    return yy+'-'+mm+'-'+dd + ' ' + hh+':'+mi+':'+ss
  }
  
  export const dateStrWEB = function(d = undefined,delta = 0 ){
    //if( !d || !isDate(d) )
    //    d = new Date()
    if( !d  )  d = new Date()
    if(delta) d.setDate(d.getDate() + delta)
    let yy = toStr2(d.getFullYear(),4)
    let mm = toStr2(d.getMonth()+1)
    let dd = toStr2(d.getDate())
    return yy+'-'+mm+'-'+dd
  }

  export const cloneObj =  function(o){
    return JSON.parse(JSON.stringify(o))
  }

  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }
 
  export const numToSTR3 = (n) => {
    //123456789 ->123 456 789
    
    n = Math.round(n)
    let s = ''+n
    let r = ''
    let j = 0
    for(let i=s.length-1;i>=0;i--){
        j = j+1;
        if(j==4){j=1;r=' '+r}
        r = s[i]+r
    }
    return r
  }

  export const getDistanceFromLatLonInM = (lat1,lon1,lat2,lon2) => {
    if(!lat1 || !lat2 || !lon1 || !lon2) return 0
    var R = 6372797.5609 //6371000; // Radius of the earth in m
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return Math.round(d);
  }

  export const FormatTXT = (s) => {
    //змінити /n на <br>
    if( s===undefined) return ''
    if(typeof s !== 'string') return ''
    
     let rez = s.replace(/\n/g,'<br />')
	 //rez = rez.replace('!!!','<span style="color:#FF0000";>')
	 //rez = rez.replace('!!','</span>')
	 return rez
}
export const txtToArray = (s) =>{
    let ar = []
    ar[0] = ''
    let n = 0 
    for(let i=0;i<s.length;i++){
        if(s.charCodeAt(i) == 13){
            n=n+1
            ar[n] = ''
        }else if(s.charCodeAt(i) == 10){ 
      //  }else if(s.charCodeAt(i) == 9){ 
      //      ar[n] = ar[n]+'____'
      //  }else if(s.charCodeAt(i) == 32){ 
      //      ar[n] = ar[n]+'_'
        }else{
            ar[n] = ar[n]+s[i]
        }
    }
    return ar
}


/*
export const sleepAndCallFn = async (ms,fn, ...args) => {
    await sleep(ms);
    return fn(...args);
}
*/
