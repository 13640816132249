import React from "react";

import { Button,Collapse,Card,CardBody,Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Form1 from "../comcom/Form1";
import Wait1 from "../comcom/Wait1"

import appData from "../AppData";
import Eng000 from "../engine/eng000";
import Engine from "../engine/engine";
import RedirectToLogin from '../components/RedirectToLogin'
import { Line1 } from "../comcom/Line1";

let changePWDCount = 0


const PageUserForm = (props) => {
    const [isLogOut, setLogOut] = React.useState(false);
    const [isTestConnection, setTestConnection] = React.useState(false);

    const testSession = async () =>{
        setTestConnection(true)
        await Eng000.testSession()
        setTestConnection(false)
    }

    const logOut = () =>{
        let r = window.confirm('Вийти з програми?')
        if( r ){
            appData.logOut()
            setLogOut(true)
        }
    }

    const [isPWD, setPWD] = React.useState(false); 
    const togglePWD = () => setPWD(!isPWD);
  
    const changePWD = async (v) =>{
        changePWDCount = changePWDCount + 1;
        const rez = await Eng000.changePWD(v.formData.PWDOLD,v.formData.PWD1,v.formData.PWD2)
        if(!rez) alert('Помилка!, спробуйте ще.')
        else {
            alert('Пароль змінено!')
            setPWD(false)
        }
        
        
    }


    if(!appData.loginOk() || isLogOut) return (
        <RedirectToLogin />
    )   
    
    const fields = [
        {fname:'PWDOLD',ftype :'password',fcaption:'Старий пароль',fhint:'Старий пароль',fnotNull:true,fval:''},
        {fname:'PWD1',ftype :'password',fcaption:'Новий пароль',fhint:'новий пароль',fnotNull:true,fval:''},
        {fname:'PWD2',ftype :'password',fcaption:'Новий пароль (2)',fhint:'новий пароль ще раз',fnotNull:true,fval:''},
    ] 

    const formCFG = {
        caption : "Змінити пароль"+".".repeat(changePWDCount),
        buttonCaption : "Змінити",
        cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : changePWD,
        actionCancel : togglePWD,
        clearData : true,
    } 

    const connInfo = Engine.connectInfo()
    const testConnToServer = async () => await Engine.testSimpleConnectToServer()

    return(
        <>
            <h4>Інформація про користувача</h4>
            <hr />
            <Line1 p={"Ім'я"} v={appData.data.userName}  />
            <Line1 p={"Login"} v={appData.data.login}  />
            <Line1 p={"Зареэструвався"} v={appData.data.dtLogin}  />
            <Line1 p={'Репіон'} v={appData.data.district}  />
            <Line1 p={'Обл'} v={appData.data.obl + appData.data.oblPart}  />
            <Line1 p={'IDS'} v={appData.data.IDS}  />
            <br />
            <br />
            {!isTestConnection && <Button color="primary"  outline onClick={testSession}>Перевірити з'єднання</Button> }
            {isTestConnection && <Wait1 msg='test connection...' />}
            <br />
            <br />
            <Button color="primary" outline onClick={logOut}>Вийти з програми</Button><br />
            <br />


            <Button color="primary"  outline onClick={togglePWD} style={{ marginBottom: '1rem' }}>
                 Змінити пароль
            </Button>

            <Modal isOpen={isPWD}  toggle={togglePWD}>
                <ModalBody>
                    <Form1 formCFG={formCFG}  />
                </ModalBody>

            </Modal>
            <br />
            <hr />
            {connInfo}
            <br />
            <Button color="primary"  outline onClick={testConnToServer}>Перевірити доступність сетвера</Button> 



        </>
    )
}

export default PageUserForm