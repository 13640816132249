import React from "react";
import { Button,Collapse,Card,CardBody,CardHeader,CardText } from "reactstrap"



import Wait1 from "./Wait1";
import Error1 from "./Error1";
import Table1 from "./Table1";



const TableSecondaryData = ({caption,loadDataFun,Param,Fields,ColumnCaption,
    Comment,
    DodButtonCaption,DodButtonAction
}) =>{

    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)

    const loadData = async ()=>{
        setloading(true)
        setData( false )
        setErr( false)        
        let d = await loadDataFun(Param)

        if(d){
            setloading(false)
            setData( d )
            setIsOpen(true)
        } else {
            setloading(false)
            setErr( true)        
        }
    }
    const btnAction = async ()=>{
        await DodButtonAction()
        await loadData()
        //alert('btnAction')
    }
    const toggle = () => setIsOpen(!isOpen);
    
    return <>
        {loading && <Wait1 />}
        {err && <Error1 />}
        {!data && !loading && <Button  outline on onClick={loadData}>{caption}</Button>}
        <div>
            {data && <Button  outline onClick={toggle}>{caption}</Button> }
            <Collapse isOpen={isOpen}>
                <Card>
                    <CardHeader>
                        <Button color="primary"  outline onClick={loadData}>Оновити</Button>
                        {DodButtonCaption && <>
                            <Button color="primary"  outline onClick={btnAction}>{DodButtonCaption}</Button>
                            </>}
                    </CardHeader>
                    <CardBody>
                        {Comment && <><br />{Comment}</>}
                        {data && <>
                            <Table1 initData= {data} headers = {ColumnCaption}  fields = {Fields}  />  
                            </> }
                    </CardBody>
                </Card>
            </Collapse>
        </div>
        
         
    </>
}

//{data && data.length>0 && <div>
//{data && data.length==0 && <>{caption} - Немає даних...</>}
export default TableSecondaryData