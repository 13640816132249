/*
Візит МП в аптеку
*/

import React from "react";
import { Button } from "reactstrap";
import { useLocation,useNavigate } from "react-router-dom";

import { RoutPath,remoteDayWorkCash } from "../WTConst";
import {dateTimeStrToStr} from "../comcom/ComFunc";
import Emo from "../comcom/Emo";
import Styles1 from "../comcom/Styles1"

import PharmacyInfo1 from "../components/Pharmacy/PharmasyInfo1";
import PharmVizitD from "../components/Pharmacy/PharmVizitD";

const PagePharmVizit = props =>{
    const location = useLocation()
    const navigate = useNavigate()
    const p = location.state

    
    const IDORG = p.IDORG
    const DTVIZIT = p.DTVIZIT
    const IDSOTR = p.IDSOTR
    const MP = p.MP
    const VR = p.VR
    const ORG = p.ORG
    const ADR = p.ADR
    const IDVIZITD = p.IDWORK

    const [cliInfo,setCliInfo] = React.useState(false)
    const toggleCliInfo = () => setCliInfo(!cliInfo)

    const goToWorkPage = () => {
        remoteDayWorkCash()
        navigate(RoutPath.Work1)
    }    
    return <>
        <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button> 
        {'  '}
        <span style={Styles1.styleBoldBLUE()}>Візит в аптеку</span> 
        <br />
        <span style={Styles1.styleBoldBLUE()}>{dateTimeStrToStr(DTVIZIT)} МП :{MP}</span>
        <br />
        {!cliInfo && <>
                <span style={Styles1.styleBold_U()}>{ORG}</span>
                <br /><span style={Styles1.styleBold()}>{ADR}</span>
                <br /> <Button  color="secondary" size="sm" outline onClick={toggleCliInfo} style={{float:'right'}}>Докладніше про аптеку...</Button>                        
                <hr />
                </>}
        
        {cliInfo && <>
            <Button  color="secondary" size="sm" outline onClick={toggleCliInfo} style={{float:'right'}}>"Згорнути ..."</Button>                        
                <PharmacyInfo1 IDORG={IDORG} />
            </>}
        <PharmVizitD IDVIZITD={IDVIZITD} IDORG={IDORG} />

            
    </>}

export default PagePharmVizit