import React from "react";
import { useLocation,useNavigate } from "react-router-dom";

import appData from "../AppData";
import Eng001 from "../engine/eng001";
import { dateStrWEB } from "../comcom/ComFunc";
import { CachingTag,remoteDayWorkCash } from "../WTConst";
import RedirectToLogin from "../components/RedirectToLogin";
import Table1AndFilter from "../comcom/Table1AndFilter";
import  usePageDataCashedWT1  from "../components/PageDataCashedWT1";
import { Button } from "reactstrap";


const PageVizitsMP = (props) =>{
   
    const location = useLocation();
    const navigate = useNavigate()

    
    const TPVIZITS = ['','ДО ЛІКАРЯ','ДО АПТЕКИ']
    let TPVIZIT = TPVIZITS[1]
    let DD1 = dateStrWEB()
    let pAdd = location.state; //IDSOTR, DD,SOTR - параметри додавання в роботи - код дата ім'я 
    let header = ''
    let IDTASKTP = 0
    if(pAdd == null) pAdd = false    
    else {
        header = 'Створюємо '+pAdd.TPTXT
        IDTASKTP = pAdd.IDTASKTP
        if(IDTASKTP in [2,4,16]) TPVIZIT = TPVIZITS[2]
    }

    
    const [currRowInTable,setCurrRowInTable] = React.useState(0)

    const loadData = async (ff) =>{
       // if((!ff.MP) || (!ff.ORGNAME && !ff.VR)) {
       //     alert('Вкажіть МП (обов"язково!) та аптеку, адресу або лікаря!') 
       //     return false
       // }
        try{
            let d = await Eng001.loadVizitMP(ff)
            return d
        }catch(err){
            return false
        }
        
    }
    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.VizitsMP,loadData)
  



    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )
    const ActionInTable = (row,fn,rowNN) => {

    }    
    const buttonProc = async (id) => {
        //pAdd = location.state; //IDSOTR, DD,SOTR
        if(pAdd.TP==='ADDTOWORK'){
            let p = {IDSOTR:pAdd.IDSOTR,DD:pAdd.DD,ID:id,IDTASKTP:IDTASKTP}
            let r = await Eng001.addRMWork(p)
            if(r>0) {
                remoteDayWorkCash()
                alert("Ok")
            }

        }
    }



    const fieldsFilterForm = [
        {fname:'MP',ftype :'text',fcaption:'МП',fhint:'',fnotNull:false,fval:appData.data.userName},
        {fname:'DFROM',ftype :'date',fcaption:'Візити З',fnotNull:false,fval:DD1},
        {fname:'DTO',ftype :'date',fcaption:'Візити до',fnotNull:false,fval:DD1},
        
        {fname:'ORGNAME',ftype :'text',fcaption:'Аптека/ЛПУ',fhint:'',fnotNull:false,fval:''},
        {fname:'ADR',ftype :'text',fcaption:'Адреса',fhint:'',fnotNull:false,fval:''},
        {fname:'VR',ftype :'text',fcaption:'Лікар',fhint:'',fnotNull:false,fval:''},
        {fname:'TPVIZIT',ftype :'select',fcaption:'Тип візиту',fhint:'',fnotNull:false,fval:TPVIZIT,fvals:TPVIZITS},
    ] 
    const Theaders = ['Дата','МП','Аптека/ЛПУ','Адреса','Лікар','Спеціальність']
    const Tfields = ['DTVIZIT','NAME1_MP','ORGNAME','ADR','VR','VRACHSPEC']

    let tableLineButton = ''
    if(pAdd) tableLineButton = 'Вибрати'


    //    <Button  color="primary" outline onClick={()=>{navigate(-1)}}>Назад</Button>
    return <>
 {header && <><h5>{header}</h5></>}   
<Table1AndFilter 
            formHeader={'Візити МП'} 
            fieldsFilterForm = {fieldsFilterForm}
            loadDataFromServer = {funLoadData}
            Theaders = {Theaders} 
            Tfields = {Tfields} 
            fieldsForAction={"ORGNAME"}
            actionInTable={ActionInTable} 
            filterOpt="1" 
            currRowInTable={currRowInTable}    
            setCurrRowInTable={setCurrRowInTable}
            data = {data}
            loading = {loading}
            err ={err}
            localStorageTag = {CachingTag.VizitsMPFORM}
            tableLineButton = {tableLineButton}
            tableLineButtonFun = {buttonProc}
            tableLineButtonFunKeyFld={'ID'}
            
        />    
    
    
    </>
}


export default PageVizitsMP