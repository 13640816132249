import React from "react";
import { Button,Collapse,Card,CardBody,CardHeader,CardText } from "reactstrap"

import Form1 from "../../comcom/Form1";
import Emo from "../../comcom/Emo";
import Eng001 from "../../engine/eng001";
import Wait1 from "../../comcom/Wait1";

import Avto1Zapr from "./Avto1zapr"



const Avto1 = (props) =>{
    let avto = props.AVTO
    const avtoMRSR = props.avtoMRSR
    const refrAvto = props.refrAvto
    const DD = props.DD
    const IDSOTR = props.IDSOTR
    const addParking = props.addParking

    const [isOpen,setOpen] = React.useState( false)
    const toggle1 = () => setOpen(!isOpen)
    const [ifWait,setWait] = React.useState( false)


    if(!avto || !Array.isArray(avto) || avto.length==0) return <></> //Авто відсутнє ...
   
    let MRSR = []
    for(let i=0;i<avtoMRSR.length;i++)  MRSR.push(avtoMRSR[i]['comment'])

    const saveAvto = async (param) => {
        param = param.formData
        
        let nAvto = [...avto]
        let ch = []
        for(let i=0;i<nAvto.length;i++){
            if(param["COM__"+i]) nAvto[i].COMMENT = param["COM__"+i]
            if(param["F1__"+i]) nAvto[i].VSBEGIN = param["F1__"+i]
            if(param["F2__"+i]) nAvto[i].VSEND = param["F2__"+i]
            ch.push({DD : DD,IDSOTR:IDSOTR,IDAVTO:nAvto[i].IDAVTO,COMMENT:nAvto[i].COMMENT,VSBEGIN:nAvto[i].VSBEGIN,VSEND:nAvto[i].VSEND})
        }
        if(ch.length > 0){
            setWait(true)
            let r = await Eng001.saveAvto(ch)
            refrAvto(nAvto)
            setWait(false)
        }
    }

    if(ifWait) return <Wait1 />
    let fields = []
    for(let i=0;i<avto.length;i++){
        fields.push({ftype :'info',fcaption:'Автомобіль : ',fval:avto[i].MARKA+ ' '+avto[i].GOSNUM})
        fields.push({fname:'COM__'+i,ftype :'text',fcaption:'Маршрут',fhint:'Введіть або виберіть',fnotNull:false,fval:avto[i].COMMENT,datalist : MRSR})
        fields.push({fname:'F1__'+i,ftype :'number',fcaption:'Початок руху',fnotNull:false,fval:avto[i].VSBEGIN})
        fields.push({fname:'F2__'+i,ftype :'number',fcaption:'Кінеці руху',fnotNull:false,fval:avto[i].VSEND})
        fields.push({ftype :'line'})
    }
    const formCFG = {
        caption : 'Дані по спідометру авто',
        buttonCaption : "Зберегти",
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : saveAvto,
        grid: 1,
    } 
//{avto[0].IDAVTO}
    return <>
            <Card  color="primary"  outline  >
                <CardHeader onClick={toggle1} style={{cursor: "pointer"}}>  
                {Emo.Avto1()}{'  '}<span style={{color:"blue", fontWeight:'bold'}}>Автомобіль </span>
                
                </CardHeader>
                <CardText>
                    <Collapse isOpen={isOpen}>
                            <Card>
                                <CardBody>
                                    <Form1 formCFG={formCFG}  />
                                    <Button  color="primary" size="sm" outline style={{float:'right'}} onClick={addParking}>{Emo.Add()}Парковка</Button>
                                    <Avto1Zapr IDAVTO={avto[0].IDAVTO} />
                                </CardBody>
                            </Card>
                        </Collapse>

                </CardText>
            </Card>

    </>
}

export default Avto1