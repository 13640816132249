import React from "react";
import { Button } from "reactstrap";


import appData from "../AppData";
import { CachingTag } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";

import RedirectToLogin from '../components/RedirectToLogin'
import Eng001 from "../engine/eng001";
import Wait1 from "../comcom/Wait1";
import Table1 from "../comcom/Table1";


const PageSpivr = (props) =>{
    const loadData = async () =>{
        try{
            let d = await Eng001.loadSpivr()
            return d
        }catch(err){
            return false
        }
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.PageSpivr,loadData)


    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )    
    if(loading) return <Wait1 />
    const Theaders = ['ПІБ','Тел','Посада','Регіон','Обл','EMAIL']
    const Tfields = ['NAME1','TEL','SOTRTPLONG','DISTRICT','OBL','EMAIL']
    return <>
        <Button color="primary" outline onClick={funLoadData}>Оновити</Button>
        <h5>Співробітники</h5>
        {!data && <Wait1 />}    
        {data && <>

            <Table1 headers = {Theaders} 
                        fields = {Tfields} 
                        initData = {data} 
                        filterOpt={1}
                        />


        </>}

    </>
}

export default PageSpivr



/*
 <Table1 headers = {Theaders} 
                        fields = {Tfields} 
                        initData = {data} 
                        fieldsForAction={fieldsForAction}
                        action={actionInTable} 
                        filterOpt={filterOpt} 
                        currRowInTable={currRowInTable}
                        tableLineButton={tableLineButton}
                        tableLineButtonFun={tableLineButtonFun}
                        tableLineButtonFunKeyFld={tableLineButtonFunKeyFld}
                        />
*/
