/*
ХУК - ДатаСет для компонента без кэшування.
Використання:

    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)

    if(loading || !data) return  <Wait1 />
    if(err) return <Error1 />
    
    if(!Array.isArray(data) || data.length===0){
        return <>    
        Error parameter....
    </>   }
    return (
        нормальний рендерінг
        )



- передаэмо функцію отримання даних (loadData)
- отримуємо
    - самі дані (data)
    - "тригер" процесу завантаження даних (loading)
    - помилку (err),fLoadData,setNewData)
    - функція (fLoadData) є обгорькою над loadData 
    - функція (setNewData) для "зовнішньої" зміни даних в ДатаСеті

Користь від ХУКа - не треба скрізь повторювати
    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
...


*/


import React from "react";
import appData from "../AppData";

export const usePageData1 = function(functLoadData = undefined,loadData = true){
    const [data,setData] = React.useState(false)
    const [loading,setloading] = React.useState(false)
    const [err,setErr] = React.useState(false)
    //if(!loadData) return  [data,loading,err]
    React.useEffect( 
        () => {
              if(appData.loginOk() && loadData)  
                fLoadData(true)
            }, [] 
    )

    const fLoadData =  async function(param){
            setloading(true)
            setData( false )
            setErr( false)
            try{
                let wd = await functLoadData(param)
                setData( wd )
                setloading(false)
            }catch(e){
                setErr(e)
            }
        }
     
     const setNewData = (newData) =>{
        setData(newData)
     }
    
     return [data,loading,err,fLoadData,setNewData,setloading]

}