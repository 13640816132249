import React from "react";
import { useLocation } from "react-router-dom";
import WorkTP1 from "../components/Work1/WorkTP1";

import appData from "../AppData"
import RedirectToLogin from "../components/RedirectToLogin";

const PageWork1RM = (props) =>{
    const location = useLocation();
    let param = location.state; 
    // pAdd : {DD: "2024-03-06" IDSOTR: 2081 IDTASK: 0 IDTASKTP: 1 SOTR : "МП (для тестування)" TP: "ADDTOWORK"}
    if(param == null) param = false

    if(!appData.loginOk() ) return (
        <RedirectToLogin />
    ) 
    return <WorkTP1 PARAM={param}/>
}

export default PageWork1RM