import React from "react"
import { Button,Collapse,Card,CardBody, CardHeader,
    Modal, ModalHeader, ModalBody } from "reactstrap"

import Emo from "../../comcom/Emo"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger,dateTimeStrToStr3 } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import Table1 from "../../comcom/Table1";
import { usePageData1 } from "../../comcom/PageData1"

const MedicardTable = ({IDORGSOTR}) =>{
    const loadData = async ()=>{
        let d = await Eng001.loadMedicard({IDORGSOTR})
        return d
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)
    const pH=['Номер','МП(при видачі)','Коли' ]
    const pF=['SN','VIDAV','DTVIDACHI' ]    
    const deleteVidachu = async (IDOST) =>{
        if(window.confirm("Відмінити видачу?")){
            await Eng001.delMedicard({IDORGSOTR,IDOST})
            fLoadData()
        }
    }
    const addCard = async () =>{
        let sn = '',snLiat = '',ifRefr = false
        sn = prompt('Номер? (6 останніх)',sn)
        while(sn !='' && sn !=null){
            if(sn.length < 6) {
                alert('Введіть не меньше 6 останніх цифр!')
            }else{
                if(snLiat.indexOf(sn)!=-1){
                    alert('Такий номер вже вводився!')
                }else{
                    snLiat = snLiat +','+sn
                    //видаємо ...
                    await Eng001.addMedicard({IDORGSOTR,sn})
                    ifRefr = true
                }
            }
            if(ifRefr)   sn = prompt('ВИДАНО! Ще номер?',sn)
            else sn = prompt('Номер? (6 останніх)',sn)
            
        }
        if(ifRefr) fLoadData()
        
    }

    if (loading) return <Wait1 />
    if (err) return <Error1 />

    return <>
        <CardHeader>
            Медікард
            <Button  outline style={{float: 'right'}} onClick={addCard}>{Emo.Add()}</Button>
        </CardHeader>
        <CardBody>
            Вибрано {data.length} записів
            <br />
            <Table1 headers = {pH} initData= {data} fields = {pF} 
                tableLineButton ={Emo.Cancel1()} 
                tableLineButtonFun={deleteVidachu}
                tableLineButtonFunKeyFld={'IDOST'}
            />    
        </CardBody>

    </>
}

const Medicard = (props) =>{
    const IDORGSOTR = toInteger(props.IDORGSOTR?props.IDORGSOTR : 0)
    const [isOpen, setIsOpen] = React.useState(false)


    const toggle = () => {
        setIsOpen(!isOpen)
    }
    


    return <>
        <Button  outline onClick={toggle} >Медікард</Button>
        <Collapse isOpen={isOpen}>
            <Card>
                 <MedicardTable IDORGSOTR={IDORGSOTR} />
            </Card>
        </Collapse>
    </>
}
export default Medicard
