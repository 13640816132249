import React from "react";
import { Button,Card,CardHeader,CardBody } from "reactstrap";

import Emo from "../../comcom/Emo";
import { Line1 } from "../../comcom/Line1";
import { LineIn1 } from "../../comcom/LineIn1";
import { usePageData1 } from "../../comcom/PageData1";
import Eng001 from "../../engine/eng001";
import Wait1 from "../../comcom/Wait1";
import {dateTimeStrToStrTime,getDistanceFromLatLonInM} from "../../comcom/ComFunc";
import ShowGeoPosition2 from "../ShowGeoPosition2";
import KrStil from "../Work1/KrStil";
import Work2dd from "./Work2dd";


const Work2d = (props) =>{
    let d = props.user

    const loadData = async () =>{
        try{
            let wd = await Eng001.getWorkData(d.IDSOTR,d.DDWORK)
            return wd
    
        }catch(err){
            return false
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)


//{w.GEOX && w.GEOX_SPR && <><br />Відхилення:{getDistanceFromLatLonInM(w.GEOX,w.GEOY,w.GEOX_SPR,w.GEOY_SPR)}м</>}

    return <>
        <Button color="primary" outline onClick={()=>{props.proc(false)}}>{Emo.Cancel1()} Назад</Button>
        <Line1 p='ПІБ' v={d.NAME1} />
            <LineIn1 p='  ' v={d.SOTRTP} />
        <Line1 p='Обл' v={d.OBL} />
        <Line1 p='Дата' v={d.DDWORK} />
            <LineIn1 p='"штатка"' v={d.METKA} />
        <Line1 p='Операцій' v={d.KOLJ} />
            <LineIn1 p='Остання' v={d.MA} />

        {loading && <Wait1 />}
        {data && <>
                {data.avto.map((d)=>
                <Card  color="primary"  outline    >
                    <CardHeader>
                    Авто: {d.MARKA} {d.GOSNUM}
                    {d.VSBEGIN && d.VSEND && <> Пробіг:{d.VSEND - d.VSBEGIN } км </>}                    
                    </CardHeader>
                    </Card>)
                }
                {data.beginDay && 
                    <Card  color="primary"  outline    >
                        <CardHeader>   
                            {data.beginDay.DTFIX && <>{Emo.Ok1()}</>}
                            Вихід на роботу :{dateTimeStrToStrTime(data.beginDay.DTFIX)}
                        </CardHeader>
                    </Card>
                }
                {data.startWork && 
                    <Card  color="primary"  outline    >
                        <CardHeader>   
                            {data.startWork.DTFIX && <>{Emo.Ok1()}</>}
                            Початок роботи :{dateTimeStrToStrTime(data.startWork.DTFIX)}
                        </CardHeader>
                        <CardBody>
                            <ShowGeoPosition2 lat1={data.startWork.GEOX} long1={data.startWork.GEOY}  error={!data.startWork.GEOY} capt1='Тут' />
                        </CardBody>
                        
                    </Card>
                }
                {data.KRSTOL && data.KRSTOL.length>0 &&
                    <Card  color="primary"  outline    >
                        <CardHeader>   
                            Круглі столи
                        </CardHeader>
                        <CardBody>
                            {data.KRSTOL.map((ks)=>(<KrStil KS={ks}/>))}
                        </CardBody>
                        
                    </Card>
                }



                {data.works && <>
                    {data.works.map((w)=>(
                          <Work2dd w={w} />
                    ))}
                    </>
                }


                </>
        }
    
    </>
}

export default Work2d
