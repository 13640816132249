
// Компонент промальовує карт гугл та наносить географічні відмітки
// const ShowGMap1 = function({center={lat: 0, lng: 0},zoom = 0,points=[]}) {
// нижче варіант виклику через задання масиву гео-відміток
// задаються координати, підпис що показується при підведенні мищки 
// та підпис на стандартній гугл-відмітці (червона кулька)
// підпис повинен бути коротким
// компонент сам обираэ центр та масштаб.
// При "прямій" вказівці параметрів - потрібно задати центр карти і масштабування zoom.
// Для орієнтиру zoom = 16 - будинки, вулиці 10 - м.Київ 5ч5см,8 - приблизно область, 4- Європа
//    const points = [
//        {lat: 50.45154, lng: 30.52553, title : 'Текст1',label : '123456'},
//        {lat: 50.45454, lng: 30.52553, title : 'Текст2',label : '2'},
//        {lat: 50.45754, lng: 30.52553, title : 'Текст3',label : '3'},
//        {lat: 50.50054, lng: 30.52553, title : 'Текст4',label : '4'},    
//      ]
//    
//      return <div style={{ height: '75vh', width: '75vh'}}>
//        <ShowGMap1 points={points}/>
//      </div>
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//В батьківському div-і ОБОВЯЗКОВО ВКАЗАТИ РОЗМІРИ
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const APIKEY = "AIzaSyCKnXAbmqt0onOiFbiiL47PeG-V1OSzj70"

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }



const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};


function GMap1({center,zoom,makrers=[]}) {
  const ref = React.useRef();
  const [map, setMap] = React.useState();
  
  React.useEffect(() => {
    if (ref.current && !map) {
        
        const mmap = new window.google.maps.Map(ref.current, {center, zoom,});
        setMap(mmap)
        
        if (makrers.length===0){
            new window.google.maps.Marker({
                position : {lat: center.lat, lng: center.lng},
                map : mmap,
                title : 'Tyt1',
                label : ''
            })
        }else{
            makrers.map((p)=> new window.google.maps.Marker({
                position : {lat: p.lat, lng: p.lng},
                map : mmap,
                title : p.title,
                label : p.label,
                //icon : FaStar
            })
            )
        }
    }
  }, [ref, map]);
  
  return (
     <div ref={ref} id="map" style={{  height: '50vh', width: '100%'}} />
  )
}

const ShowGMap1 = function({center={lat: 0, lng: 0},zoom = 0,points=[]}) {
    //zoom = 16 - будинки, вулиці 10 - м.Київ 5ч5см,8 - приблизно область, 4- Європа
    
    if(points.length===1 && center.lat === 0) {center.lat = points[0].lat;center.lng = points[0].lng; zoom = 16} 
    if(points.length>1) {
        let maxLat = points[0].lat, minLat = points[0].lat,maxLng = points[0].lng, minLng = points[0].lng
        points.map((p)=>{
            if(maxLat < p.lat) maxLat = p.lat 
            if(minLat > p.lat) minLat = p.lat
            if(maxLng < p.lng) maxLng = p.lng 
            if(minLng > p.lng) minLng = p.lng
        })
        if(center.lat === 0){
            center.lat = minLat + (maxLat-minLat)/2
            center.lng = minLng + (maxLng-minLng)/2
        }
        let r = getDistanceFromLatLonInKm(minLat,minLng,maxLat,maxLng)
        if(r<10) zoom=12
        else if(r<50) zoom=10
        else if(r<100) zoom=8
        else zoom = 6
    } 
    if(center.lat === 0) center={lat: 50.45124, lng: 30.52553}
    if(zoom===0) zoom = 10

    return (
      
      <Wrapper apiKey={APIKEY} render={render}>
        <GMap1 center={center} zoom={zoom} makrers={points} />
      </Wrapper>
      
  );
}


export default ShowGMap1