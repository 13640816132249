import React from "react"
import {dateTimeStrToStr1} from "../../comcom/ComFunc"

import Emo from "../../comcom/Emo";

const KrStil =  (props) => {
    let KS = props.KS
    return<>
        Тип: {KS.KRSTOL} {'  '}
        {KS.COMMENT_FAKT && <>Тема: {KS.COMMENT_FAKT}</>}
        <br />{KS.ORGNAME} {KS.ORGTXT}
        <br />{KS.ADR} Присутніх {KS.KOL_PREZENT}
        {KS.SOTRORG && (<>
                <br />{KS.SOTRORG} {KS.VRACHSPEC} {KS.TEL}
            </>)}
        {KS.COMMENT_RM && <><br />{KS.COMMENT_RM}</>}
        <hr />
    </>


}

export default KrStil

