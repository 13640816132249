import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap"


import Eng001 from "../../engine/eng001";
import { RoutPath,setOkInDayWorkCash,remoteDayWorkCash } from "../../WTConst";
import { usePageData1 } from "../../comcom/PageData1";
import Wait1 from "../../comcom/Wait1";
import Error1 from "../../comcom/Error1";
import Form1 from "../../comcom/Form1";
import { usePosition } from "../../comcom/geo1"
import ShowGeoPosition from "../ShowGeoPosition";
import Styles1 from "../../comcom/Styles1";
import Op from "../Com/Op";


const DoctorVizitD1 = (props) =>{
    const IDVV = props.IDVV
    const IDORGSOTR = props.IDORGSOTR
    const IDORG = props.IDORG
    let IDVIZITTP = 1 //1 - норм візит 2 - дист
    let CAN_DIST_VIZIT = 0
    let CAN_VIZIT_NO_COORD = 0
    const { latitude, longitude, wait, error } = usePosition()
    const navigate = useNavigate()

    const loadData = async () => {
        let d = await Eng001.getDoctorVizitData({IDVV:IDVV}) 
        let op = await Eng001.loadOp({'IDOPTP':3,'IDVIZITD':IDVV,IDORG,IDORGSOTR})
        d["OP"] = op
        return d
    }

    const [data,loading,err,fLoadData,setNewData,setLoad] =  usePageData1(loadData)

    const saveData = async (param) => {
        if(!latitude && !CAN_VIZIT_NO_COORD) {
            alert('Не визначено координати ...')
            return
        }
        if( !latitude ) {
            if (!window.confirm('Не визначено координати, ПРОДОВЖУЄМО?'))
                return
        }

        param = param.formData
        param.latitude = latitude
        param.longitude = longitude
        param.IDVV = IDVV
        if(!param.IDVIZITTP) param.IDVIZITTP = 1
        else param.IDVIZITTP = param.IDVIZITTP + 1 
        setLoad(true)
        let r = await Eng001.saveDoctorVizit(param)
        setLoad(false)
        //setOkInDayWorkCash(4,IDVV)
        remoteDayWorkCash()
        navigate(RoutPath.Work1)
    }
 
    if(loading || !data) return  <Wait1 />
    if(err) return <Error1 />
    
    if(!Array.isArray(data) || data.length===0){
        return <>    
        Error parameter....
    </>
    }

    const fields = []
    fields.push({fname:'IFDVV',ftype :'select',fcaption:'З Вами проводять подвійний візит?',fval:data[0].IFDVV,fvals:['','Y','N']})
    fields.push({fname:'DVV',ftype :'text',fcaption:'Хто проводить',fval:data[0].DVV})
    data.map((d,i)=>{
        fields.push({fname:'F___'+d.IDVV2,ftype :'checkbox',fcaption:d.PROMOPREP,fval:d.IFF})
    })

    IDVIZITTP = data[0]['IDVIZITTP']
    CAN_DIST_VIZIT = data[0]['CAN_DIST_VIZIT']
    CAN_VIZIT_NO_COORD = data[0]['CAN_VIZIT_NO_COORD']

    if(CAN_DIST_VIZIT=='1'){
        fields.push({fname:'F',ftype :'line'})
        fields.push({fname:'IDVIZITTP',ftype :'checkbox',fcaption:'Дистанційний візит',fval:0,
        msg : 'Дистанційний візит тільки з дозволу керівника'})
    }
    //fields.push({ftype :'info',fcaption:'Дистанційний візит тільки з дозволу керівника',})

    //(тільки з дозволу керівника)
    //IDVIZITTP

    //switch
    //checkbox


    const formCFG = {
        caption : 'Дані візиту',
        buttonCaption : "Зберегти",
        cancelBtnCaption : '',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : saveData,
        //actionCancel : togglePWD,
        //clearData : true,
        //localStorageTag : 'form1',
    } 



    const ifOp = (data['OP'].length>0 )
    return <>
        {ifOp &&  <>
            <h4>Заповніть опитування:</h4>
            {data['OP'].map((m,i) => <Op OP={m} OPOPEN={1}/>)}   
            <hr />
            </>
            }    
        <Form1 formCFG={formCFG}  />
        <ShowGeoPosition latitude={latitude} longitude ={longitude} wait={wait} error={error} />
    </>

}

export default DoctorVizitD1