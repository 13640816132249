import React from "react";
import { Collapse, Button, CardBody, Card } from 'reactstrap';


import appData from "../AppData";
import { CachingTag } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";

import RedirectToLogin from '../components/RedirectToLogin'
import Eng001 from "../engine/eng001";
import Wait1 from "../comcom/Wait1";
import Table1 from "../comcom/Table1";
import { numToSTR3 } from "../comcom/ComFunc";
import CliBazaMP from "../components/Work2/CliBazaMP"


const PageHidProd = (props) =>{

    const [isOpen0, setIsOpen0] = React.useState(false);
    const toggle0 = () => setIsOpen0(!isOpen0);
    const [isOpen1, setIsOpen1] = React.useState(false);
    const toggle1 = () => setIsOpen1(!isOpen1);
    const [isOpen2, setIsOpen2] = React.useState(false);
    const toggle2 = () => setIsOpen2(!isOpen2);


    const loadData = async () =>{
        try{
            let d = await Eng001.loadHidProd()
            let d1 = false,d2 = false, d3 = false,d4 = false
            let d0 = d['d0']
            if(d && Array.isArray(d['d1']) && d['d1'].length>0)   {
                 d1 = d['d1'] 
                 if(d1[0]['PP'] > 0) d1[0]['PC'] = Math.round((d1[0]['SUMR']/d1[0]['PP'])*100)
                 else d1[0]['PC'] = '-'
            }
            if(d && Array.isArray(d['d2']) && d['d2'].length>0)   {
                d2 = d['d2'] 
                for(let i=0;i<d2.length;i++){
                    d2[i]['PC'] = 0
                    if(d2[i]['PLANS'] >0) d2[i]['PC'] = Math.round(d2[i]['CUMULATIVE']/d2[i]['PLANS']*100)
                    d2[i]['PC'] = d2[i]['PC'] +'%'
                    d2[i]['CUMULATIVE'] = numToSTR3(d2[i]['CUMULATIVE']/1000)
                    d2[i]['PLANS'] = numToSTR3(d2[i]['PLANS']/1000)
                    d2[i]['TOMORROW'] = numToSTR3(d2[i]['TOMORROW']/1000)
                }
                if(d2.length==1) setIsOpen0(true)
            }
            if(d && Array.isArray(d['d3']) && d['d3'].length>0){
                   d3 = d['d3'] 
                   for(let i=1;i<d3.length;i++) d3[i]['F2'] = numToSTR3(d3[i]['F2'])
        
            }
            if(d && Array.isArray(d['d4']) && d['d4'].length>0){
                d4 = d['d4'] 
                for(let i=0;i<d4.length;i++) {
                    if(d4[i]['PP']){
                        d4[i]['PC'] = Math.round(d4[i]['SUMR']/d4[i]['PP']*100)+'%'
                    }
                }
            }
            return {d0,d1,d2,d3,d4}
        }catch(err){
            return false
        }
    }

    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.HidProd,loadData)


    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )    
    if(loading) return <Wait1 />
    

    return <>
    <Button color="primary" outline onClick={funLoadData}>Оновити</Button>
    {data['d0'] && <><h5>{data['d0']}</h5></>}
    {data['d3'] && <>
        <h5>По фірмі</h5>
        <Table1 headers={['Хід продажів','']} fields={['F1','F2']} initData={data['d3']} align = {'R'}/>
    </>}
    
    {data['d2'] && <>
        <Button color="primary" outline onClick={toggle0}>По обл.</Button>
      <Collapse isOpen={isOpen0} >
        <Card>
          <CardBody>
              <h5>По обл. за місяць(в тис.грн!!!)</h5>
              <Table1 headers={['Обл','План','Прод','% викон','Відкл']} 
                   fields={['OBL','PLANS','CUMULATIVE','PC','TOMORROW']} 
                    initData={data['d2']} align = {'R'}/>

          </CardBody>
        </Card>
      </Collapse>
      <br />
    </>}
    {data['d1'] && <>
      <br />  
            <h5>Виконання по МП</h5>
            <Table1 headers={['МП','Рег','Обл','План','Прод','% викон']} 
                fields={['NAME1','DISTRICT','OBL','PP','SUMR','PC']} 
                initData={data['d1']}  filterOpt="1"/>      
      <br />
    </>}


    {data['d4'] && <>
      <br />  
      <Button color="primary" outline onClick={toggle1} >По МП</Button>
      <Collapse isOpen={isOpen1} >
        <Card>
          <CardBody>
            <h5>Виконання по МП</h5>
            <Table1 headers={['МП','Рег','Обл','План','Прод','% викон']} 
                fields={['NAME1','DISTRICT','OBL','PP','SUMR','PC']} 
                initData={data['d4']}  filterOpt="1"/>      
          </CardBody>
        </Card>
      </Collapse>
      <br />
    </>}
    <Button color="primary" outline onClick={toggle2} >База МП</Button>
      <Collapse isOpen={isOpen2} >
        <Card>
          <CardBody>
            <CliBazaMP />    
          </CardBody>
        </Card>
      </Collapse>

    </>
}



export default PageHidProd