//Співробітники аптек
import React from "react"
import { Button,Collapse, CardBody, Card,CardHeader
    ,Modal, ModalHeader, ModalBody } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import Table1 from "../../comcom/Table1"
import Emo from "../../comcom/Emo"
import EmplEdit1 from "../Empl/EmplEdit1"
import { usePageData1 } from "../../comcom/PageData1"


const PharmacyEmpl = (props) =>{
    const IDORG = toInteger(props.IDORG)
    const IFLIKAR = props.IFLIKAR
    const [IDORGSOTR_EDIT,setIDORGSOTR_EDIT] = React.useState(0)

    const [isOpen, setIsOpen] = React.useState(true)


    const [addWindow, setAddWindow] = React.useState(false);
    const [editWindow, setEditWindow] = React.useState(false);

    const addEmpl = async (p) =>{
        setAddWindow(false)

        //let param = {IDORG:IDORG,IDORGSOTR:IDORGSOTR,IDPROMOM:p}
        //await execPromo(param)

    }

    const loadData = async ()=>{     
        let d = await Eng001.loadPharmacyEmpl({IDORG:IDORG})
        if(d) return  d 
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)

    const toggle = async () => setIsOpen(!isOpen)    
    const sotrH=['ПІБ','Посада','Лояльн','Перс.тел','ДН' ]
    const sotrF=['NAME1','DOLGN','LL','TELPERS','BIRTHDAY' ]
    const toggleAdd = () => setAddWindow(!addWindow)
    const toggleEdit = () => setEditWindow(!editWindow)
    const closeAddEditWindow = () =>{
        if(addWindow)  setAddWindow(!addWindow)
        if(editWindow) setEditWindow(!editWindow)
        fLoadData()
    }
    const startEditSotr = (ID)=>{

        setIDORGSOTR_EDIT( ID )
        setEditWindow(true)
    }

    if(!IDORG) return <></>
    return <>
    
        {loading && <Wait1 />}
        {err && <Error1 />}
        {data && <div>
                    <Button  outline onClick={toggle} >Спіробітники</Button>
                    <Collapse isOpen={isOpen}>
                        <Card>
                            <CardHeader>
                                {IDORG && <Button  outline style={{float: 'right'}} onClick={toggleAdd}>{Emo.Add()}</Button>}
                            </CardHeader>

                            <CardBody>
                                <Table1 headers = {sotrH} initData= {data} fields = {sotrF} 
                                    tableLineButton ={Emo.Edit1()} 
                                    tableLineButtonFun={startEditSotr}
                                    tableLineButtonFunKeyFld={'IDORGSOTR'}                                
                                
                                
                                />
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
        }
        <Modal isOpen={addWindow} toggle={toggleAdd}>
            <ModalHeader toggle={toggleAdd}>Додаємо...</ModalHeader>
            <ModalBody>
                <EmplEdit1 IDORG={IDORG} IDORGSOTR={0} FUN={closeAddEditWindow} IFLIKAR={IFLIKAR}/>
            </ModalBody>
        </Modal>
        <Modal isOpen={editWindow} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>Редагуємо...</ModalHeader>
            <ModalBody>
                <EmplEdit1 IDORG={IDORG} IDORGSOTR={IDORGSOTR_EDIT} FUN={closeAddEditWindow} IFLIKAR={IFLIKAR}/>
            </ModalBody>
        </Modal>


    </>
}

export default PharmacyEmpl