import React from "react";
import { useLocation } from "react-router-dom";

import appData from "../AppData";
import Eng001 from "../engine/eng001";

import {dateTimeStrToStr} from "../comcom/ComFunc";
import RedirectToLogin from '../components/RedirectToLogin'
import usePageDataCashedWT1  from "../components/PageDataCashedWT1";
import Table1AndFilter from "../comcom/Table1AndFilter";
import LookPharmacy1 from "../components/Pharmacy/Pharmacy2";
import {CachingTag} from "../WTConst"

const PagePharmacy1 = props => {
    const location = useLocation();
    let pAdd = location.state; //IDSOTR, DD,SOTR - параметри додавання в роботи - код дата ім'я 
    if(pAdd == null) pAdd = false
    const [IDORG,setIDORG] = React.useState(0)
    const [currRowInTable,setCurrRowInTable] = React.useState(0)

    const loadData = async (ff) =>{
        if(!ff.ORGNAME && !ff.ADR && !ff.MP && !ff.OBL){
            alert('Задайте параметри пошуку...')
            return false
        }
        try{
            let d = await Eng001.getPharmacy1(ff)
            setCurrRowInTable(0)
            if(Array.isArray(d) && d.length==200){
                alert('Вибрано 200 перших записів, уточнюйте фільтр')
            }
            return d
        }catch(err){
            return false
        }
    }
    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.PharmacyCash,loadData)
  
    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )

    const fieldsFilterForm = [
        {fname:'ORGNAME',ftype :'text',fcaption:'Назва',fhint:'',fnotNull:false,fval:''},
        {fname:'ADR',ftype :'text',fcaption:'Адреса',fhint:'',fnotNull:false,fval:''},
        {fname:'MP',ftype :'text',fcaption:'Закріплення',fhint:'',fnotNull:false,fval:''},
        {fname:'OBL',ftype :'text',fcaption:'Область',fhint:'',fnotNull:false,fval:''},
        {fname:'TP',ftype :'select',fcaption:'Тип',fhint:'',fnotNull:false,fval:'Аптека',fvals:['Аптека','ЛПЗ',' ']},
        {fname:'IFWORK',ftype :'select',fcaption:'Працюючі',fhint:'',fnotNull:false,fval:'Y',fvals:['Y','N',' ']},
    ] 
    const Theaders = ['Назва','Адреса','Мережа','Закріплення','Тип','ABC','Ост.візит','Обл','Робоча']
    const Tfields = ['ORGNAME','ADR','NET_TXT','MP','TPZAKR','ABC','DTLASTVIZIT','OBL','IFWORK']

    const ActionInTable = (row,fn,rowNN) => {
        let idorg_ = row['IDORG']
        if(idorg_ && idorg_>0) setIDORG(idorg_) 
        setCurrRowInTable(rowNN)
        //rowNN_ = rowNN
    }
    const buttonProc = async (idorg) => {
        //pAdd = location.state; //IDSOTR, DD,SOTR
        if(pAdd.TP==='ADDTOWORK'){
            let p = {IDSOTR:pAdd.IDSOTR,DD:pAdd.DD,IDORG:idorg}
            let r = await Eng001.addPharmToVizit(p)
        }
    }

    const hideDetail = () => setIDORG(0)
    if (IDORG>0){
        return <LookPharmacy1 IDORG={IDORG} funcBack={hideDetail}/>
    }
    let tableLineButton = ''
    if(pAdd) tableLineButton = 'Додати'
    return (
        <>
        {pAdd && <h5 style={{color:'blue', textDecoration:'underline'}}>Додаємо до візитів {dateTimeStrToStr(pAdd.DD)} {pAdd.SOTR} </h5>}
        <Table1AndFilter 
            formHeader={'Аптеки/ЛПЗ'} 
            fieldsFilterForm = {fieldsFilterForm}
            loadDataFromServer = {funLoadData}
            Theaders = {Theaders} 
            Tfields = {Tfields} 
            fieldsForAction={"ORGNAME"}
            actionInTable={ActionInTable} 
            filterOpt="1" 
            currRowInTable={currRowInTable}    
            setCurrRowInTable={setCurrRowInTable}
            data = {data}
            loading = {loading}
            err ={err}
            localStorageTag = {CachingTag.PharmacyFormFilter}
            tableLineButton = {tableLineButton}
            tableLineButtonFun = {buttonProc}
            tableLineButtonFunKeyFld={'IDORG'}
            
        />
        </>        
    )
}

export default PagePharmacy1