import React from "react";
import { Accordion,AccordionHeader,AccordionBody,AccordionItem, Button,Table } from "reactstrap";


import appData from "../AppData";
import RedirectToLogin from '../components/RedirectToLogin'
import TableMonth from "../components/Tabel/TabelMonth";
import TableDay from "../components/Tabel/TableDay";


const PageTabel = props =>{
    const [open, setOpen] = React.useState('0');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };



    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )    

    return <>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">Поточний день</AccordionHeader>
          <AccordionBody accordionId="1">
            <TableDay />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">За місяць</AccordionHeader>
          <AccordionBody accordionId="2">
                <TableMonth />
          </AccordionBody>
        </AccordionItem>
        </Accordion>    
    
    
    
    </>
}

export default PageTabel