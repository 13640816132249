import React from "react";
import { useNavigate } from "react-router-dom";
import {Button,Collapse} from 'reactstrap'

import appData from "../AppData";
import Emo from "../comcom/Emo";

const Menu1 = props =>{
    const navigate = useNavigate()
    const [openMNU, setOpenMNU] = React.useState(false);
    const toggle = () => setOpenMNU(!openMNU)

    if(!appData.loginOk()) return;
  
    const mnu = appData.data.mnu
    let mnuFavor = mnu.filter((m)=>m.FAVOR==='1')
    if (mnuFavor.length>0){
        return(
            <>
            {mnuFavor.map((m,idx) => <div key={idx}>
                <Button  color="link" onClick={()=>navigate(`/mnu/${m.FNUMBER}`)}>{m.FNAME}</Button><br /></div>)
                }
            <Button color="link" outline onClick={toggle} style={{color:'blue',  textDecoration: 'underline', fontSize:20}}>{'... \u2193\u2191'}</Button>
            <Collapse isOpen={openMNU}>
                <Button color="link" onClick={()=>navigate('/user')}>Користувач</Button><br />
                {mnu.map((m,idx) => <div key={idx}><Button  color="link" onClick={()=>navigate(`/mnu/${m.FNUMBER}`)}>{m.FNAME}</Button><br /></div>)}

                <Button color="link" onClick={()=>navigate('/TestCoordinat')}>ГЕО-тест</Button>
            </Collapse>
            </>
        )
    }
    //<Button color="link" onClick={()=>navigate('/pharmacy1')}> Аптеки </Button> <br />
    return(
        <>
        {mnu.map((m,idx) => <div key={idx}><Button key={idx} color="link" onClick={()=>navigate(`/mnu/${m.FNUMBER}`)}>{m.FNAME}</Button><br /></div>)}
        <Button color="link" onClick={()=>navigate('/user')}>Користувач</Button>
        <br />
        </>
    )
}

export default Menu1