/*

    const togglePWD = () => setPWD(!isPWD);
  
    const changePWD = async (v) =>{
        //v.formData - тут дані форми, в даному випадку
        //v.formData.PWDOLD,v.formData.PWD1,v.formData.PWD2

        const rez = await Eng000.changePWD(v.formData.PWDOLD,v.formData.PWD1,v.formData.PWD2)
        if(!rez) alert('Помилка!, спробуйте ще.')
        else {
            alert('Пароль змінено!')
            setPWD(false)
        }
        
        
    }

    const fields = [
        {fname:'PWDOLD',ftype :'password',fcaption:'Старий пароль',fhint:'Старий пароль',fnotNull:true,fval:''},
        {fname:'PWD1',ftype :'password',fcaption:'Новий пароль',fhint:'новий пароль',fnotNull:true,fval:''},
        {fname:'PWD2',ftype :'password',fcaption:'Новий пароль (2)',fhint:'новий пароль ще раз',fnotNull:true,fval:''},
    ] 

    const formCFG = {
        caption : "Змінити пароль"+".".repeat(changePWDCount),
        buttonCaption : "Змінити",
        cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : changePWD,
        actionCancel : togglePWD,
        clearData : true,
        localStorageTag : 'form1',
    } 

...
<Form1 formCFG={formCFG}  />
*/



import React from "react";

import { Button,Form } from "reactstrap";

import {UnicString1} from './ComFunc'
import Input1 from  "./Input1"
import Alert1 from "./Alert1";

const Form1 = props =>{
    let {formName,
        fields,
        action,
        caption,
        buttonCaption,
        formMsg,
        formErrorMsg,
        cancelBtnCaption,
        actionCancel,
        clearData,
        grid=0,
        localStorageTag } = props.formCFG

    if(!formName){
       formName = 'F'+UnicString1()
    }

    if(localStorageTag){
        let prevData = localStorage.getItem(localStorageTag)
        if( prevData){
            prevData = JSON.parse(prevData)
            for(let i=0; i<fields.length; i++){
                if(prevData[fields[i].fname]) {
                    fields[i].fval = prevData[fields[i].fname]
                }
            }
        }
    }

    let startVal =[]
    fields.map((v)=>startVal[v.fname]=v.fval)

    const [values,setValues] = React.useState(startVal)
    const onChange = event =>{
        if(event.target.name) setValues({...values,[event.target.name]:event.target.value})
    }   
    fields.map((v)=>v['fonChange']=onChange)
   
    if(!buttonCaption) buttonCaption = 'Ok'

    const click1 = () => {
        action({formData:{...values}})
        if(localStorageTag){
            localStorage.setItem(localStorageTag,JSON.stringify(values))
        }
        if(clearData){
            document[formName].reset();
        }
    }
    const click2 = () => actionCancel({formData:{...values}})
    return (
        <Form name={formName}
         onSubmit={event => {
            event.preventDefault()
            action({formData:{...values}})
          }}
        >
            {caption && (<div className="text-center"><h4>{caption}</h4></div>)}
            {formErrorMsg && (<Alert1 msg={formErrorMsg} type="danger" canClose={1}/>)}
            {formMsg && (<Alert1 msg={formMsg} type="info" canClose={1}/>)}
            {fields.map( (inpI,i) => <Input1 {...inpI} key={i} grid={grid}/>)}
            <Button  color="primary" outline  onClick={click1}>{buttonCaption}</Button>{' '}
            {cancelBtnCaption && (<Button  outline  onClick={click2}>{cancelBtnCaption}</Button>)}
        </Form>
    )
}

//setValues({...values,aaa:3})

export default Form1